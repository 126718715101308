import { AxiosInstance } from "axios";

import { KycUrlGenerator } from "./url-generator";
import {
  KycPersonalInfoRequest,
  KycPersonalInfoResponse,
  KycUploadFileRequest,
  KycUploadFileResponse,
} from "./repo";

import { ResponseData } from "@/shared/constants/interfaces";
import {
  KycPersonalInfoResponseFactory,
  KycUploadFileResponseFactory,
} from "./factory";

export interface KycProjectRepository {
  personalInfo(userId: number): Promise<KycPersonalInfoResponse>;
  personalInfoById(id: number): Promise<KycPersonalInfoResponse>;
  personalInfoUpdate(
    payload: KycPersonalInfoRequest
  ): Promise<KycPersonalInfoResponse>;

  uploadFile(payload: KycUploadFileRequest): Promise<KycUploadFileResponse>;
  deleteFile(id: number): Promise<void>;
  userFiles(userId: number): Promise<KycUploadFileResponse[]>;
  userFilesById(id: number): Promise<KycUploadFileResponse[]>;

  verification(): Promise<KycPersonalInfoResponse>;
}

export class KycHttpRepo implements KycProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: KycUrlGenerator
  ) {}

  async personalInfo(userId: number): Promise<KycPersonalInfoResponse> {
    const { data } = await this.axios.get<
      ResponseData<KycPersonalInfoResponse>
    >(this.urlGenerator.personalInfo(), {
      params: {
        id: userId,
        user_id: userId,
      },
    });

    return KycPersonalInfoResponseFactory(data.data);
  }

  async personalInfoById(id: number): Promise<KycPersonalInfoResponse> {
    const { data } = await this.axios.get<
      ResponseData<KycPersonalInfoResponse>
    >(this.urlGenerator.personalInfo(), {
      params: {
        id,
        user_id: id,
      },
    });

    return KycPersonalInfoResponseFactory(data.data);
  }

  async personalInfoUpdate(
    payload: KycPersonalInfoRequest
  ): Promise<KycPersonalInfoResponse> {
    const { data } = await this.axios.post<
      ResponseData<KycPersonalInfoResponse>
    >(this.urlGenerator.personalInfo(), payload);

    return KycPersonalInfoResponseFactory(data.data);
  }

  async uploadFile(
    payload: KycUploadFileRequest
  ): Promise<KycUploadFileResponse> {
    const { data } = await this.axios.post<ResponseData<KycUploadFileResponse>>(
      this.urlGenerator.uploadFile(),
      payload
    );

    return KycUploadFileResponseFactory(data.data);
  }

  async deleteFile(id: number): Promise<void> {
    await this.axios.delete<void>(`${this.urlGenerator.deleteFile()}${id}`);
  }

  async userFiles(userId: number): Promise<KycUploadFileResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<KycUploadFileResponse[]>
    >(this.urlGenerator.userFiles(), {
      params: {
        id: userId,
        user_id: userId,
      },
    });

    return data.data.map((item) => KycUploadFileResponseFactory(item));
  }

  async userFilesById(id: number): Promise<KycUploadFileResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<KycUploadFileResponse[]>
    >(this.urlGenerator.userFiles(), {
      params: {
        id,
        user_id: id,
      },
    });

    return data.data.map((item) => KycUploadFileResponseFactory(item));
  }

  async verification(): Promise<KycPersonalInfoResponse> {
    const { data } = await this.axios.patch<
      ResponseData<KycPersonalInfoResponse>
    >(this.urlGenerator.verification());

    return KycPersonalInfoResponseFactory(data.data);
  }
}
