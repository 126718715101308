<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <tr :class="b('', { disabled: isAdminShow })">
    <td>
      <div :class="b('created-at')">
        <p :class="b('label')">
          {{ displayedCreatedAt }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('user-id')">
        <p :class="b('label')">
          {{ row.user_id }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('email')">
        <p :class="b('label')">
          {{ row.user.email }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('app-type')">
        <p :class="b('label')">
          {{ displayedAppType }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('amount')">
        <p :class="b('label')">
          <span
            :class="
              b('label-amount', {
                deposit: isDeposit,
                withdrawal: isWithdrawal,
              })
            "
          >
            {{ displayedAmount }}
          </span>
        </p>

        <p :class="b('label')">
          {{ row.currency.name.toUpperCase() }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('execution-price')">
        <p :class="b('label')">
          {{ row.execution_price }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('transaction-volume')">
        <p :class="b('label')">
          {{ row.transaction_volume }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('commissions')">
        <p :class="b('label')">
          {{ row.commissions }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('number')">
        <p :class="b('label')">
          {{ row.id }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('status')">
        <span :class="b('icon')" v-html="displayedStatusIcon"></span>

        <p :class="b('label')">
          {{ displayedStatus }}
        </p>
      </div>
    </td>

    <td v-if="!isAdminShow">
      <div :class="b('stage')">
        <div v-if="isStageGroupShow" :class="b('stage-group')">
          <button :class="b('stage-work')" @click="handleInWork">
            {{ $t("В работу") }}
          </button>

          <button
            :class="b('action')"
            :disabled="isDisabled"
            @click="handleRemove"
          >
            <span :class="b('icon')" v-html="displayedSvg.remove"></span>
          </button>
        </div>

        <p v-else :class="b('label')">
          {{ row.stage }}
        </p>

        <div :class="b('action')">
          <button
            :class="b('toggle', { active: isVisible })"
            @click="handleToggle"
          >
            <span :class="b('toggle-icon')" v-html="displayedSvg.dots"></span>
          </button>

          <div v-if="isVisible" :class="b('options', { active: isVisible })">
            <button
              v-for="option in displayedOptions"
              :key="option.id"
              :class="b('option')"
              :disabled="option.isDisabled"
              @click="handleOption(option)"
            >
              {{ option.label }}
            </button>
          </div>
        </div>
      </div>
    </td>

    <td v-else>
      <span :class="b('admin')">
        {{ row.admin.email }}
      </span>
    </td>
  </tr>
</template>
