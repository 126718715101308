<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">{{ $t("Создание заявки") }}</h1>

    <div :class="b('wrapper')">
      <component
        :is="displayedComponent"
        :requisites="requisites"
        v-model:component="component"
        v-model:is-loading="isLoading"
        v-model:is-result="isResult"
        v-model:is-verification="isVerification"
      />
    </div>

    <transition name="opacity">
      <ApplicationResult
        v-if="isResult"
        :class="b('modal')"
        v-model:is-loading="isLoading"
        v-model:is-result="isResult"
        v-model:is-confirm="isConfirm"
      />
    </transition>

    <transition name="opacity">
      <ApplicationConfirm
        v-if="isConfirm"
        :class="b('modal')"
        v-model:is-loading="isLoading"
        v-model:is-confirm="isConfirm"
      />
    </transition>

    <transition name="opacity">
      <ApplicationVerification
        v-if="isVerification"
        :class="b('modal')"
        v-model:requisites="requisites"
        v-model:component="component"
        v-model:is-verification="isVerification"
      />
    </transition>
  </div>
</template>
