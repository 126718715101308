import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedUpload,
  SharedDownload,
  SharedValidate,
} from "@/components/shared";
import { UIButton } from "@/components/ui";

import { VerificationKybComponent } from "@/views/verification/model";
import {
  FileFor,
  FileType,
  KybDocumentsFileName,
} from "@/shared/constants/enums";
import { FileParams, KybDocuments } from "@/shared/constants/interfaces";
import {
  KybBasicInfoResponseFactory,
  KybRegistrationInfoResponseFactory,
  KybUploadFileRequestFactory,
  KybUploadFileResponseFactory,
} from "@/shared/repository/modules/kyb/factory";
import { KybUploadFileResponse } from "@/shared/repository/modules/kyb/repo";
import { extractFileName } from "@/shared/utils/file-name-helpers";
import { ParserDocument } from "@/shared/repository/modules/parser/enums";
import { ParserParseRequest } from "@/shared/repository/modules/parser/repo";
import { ParserParseRequestFactory } from "@/shared/repository/modules/parser/factory";
import {
  UserFileRequestFactory,
  UserFileResponseFactory,
} from "@/shared/repository/modules/user/factory";
import { UserFileResponse } from "@/shared/repository/modules/user/repo";
import { FILES_LENGTH } from "@/shared/repository/modules/user/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedUpload,
    SharedDownload,
    SharedValidate,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      userRepo: this.$projectServices.userRepo,
      kybRepository: this.$projectServices.kybRepository,
      userRepository: this.$projectServices.userRepository,
      kybBasicInfo: KybBasicInfoResponseFactory(),
      kybRegistrationInfo: KybRegistrationInfoResponseFactory(),
      file: UserFileResponseFactory(),
      documents: Object.keys(KybDocumentsFileName).reduce(
        (documents, fileName) => {
          documents[fileName] = KybUploadFileResponseFactory({
            name: KybDocumentsFileName[fileName as KybDocumentsFileName],
          });

          return documents;
        },
        {} as KybDocuments
      ) as KybDocuments,
      files: <UserFileResponse[]>[],
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      const [kybBasicInfo, kybRegistrationInfo, userFiles, files] =
        await Promise.allSettled([
          this.kybRepository.basicInfo(this.userRepo.userInfo.id),
          this.kybRepository.registrationInfo(this.userRepo.userInfo.id),
          this.kybRepository.userFiles(this.userRepo.userInfo.id),
          this.userRepository.files(FileFor.kyb, this.userRepo.userInfo.id),
        ]);

      if (kybBasicInfo.status === "fulfilled") {
        this.kybBasicInfo = kybBasicInfo.value;
      }

      if (kybRegistrationInfo.status === "fulfilled") {
        this.kybRegistrationInfo = kybRegistrationInfo.value;
      }

      if (userFiles.status === "fulfilled") {
        userFiles.value.forEach((userFile) => {
          this.documents[extractFileName(userFile.name)] =
            KybUploadFileResponseFactory(userFile);
        });
      }

      if (files.status === "fulfilled") {
        this.files = files.value;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedQuestionnaireOfALegalEntity(): ParserParseRequest {
      const { organisation, inn, registration_date, regional_number } =
        this.kybBasicInfo;
      const { organisation_legal_form, address } = this.kybRegistrationInfo;

      return ParserParseRequestFactory(
        {
          companyName: organisation,
          opf: organisation_legal_form,
          inn: inn,
          dateRegistration: registration_date,
          numberRegistration: regional_number,
          address: address,
        },
        ParserDocument["Юр лицо: Анкета юридического лица"]
      );
    },

    displayedQuestionnaireOfTheBeneficialOwner(): ParserParseRequest {
      return ParserParseRequestFactory(
        {},
        ParserDocument["Юр лицо: Анкета бенефициарного владельца"]
      );
    },

    displayedBrokerageAgreement(): ParserParseRequest {
      const { id, email } = this.userRepo.userInfo;

      return ParserParseRequestFactory(
        {
          id,
          email,
        },
        ParserDocument["Юр лицо: Брокерский договор"]
      );
    },

    displayedRiskDeclaration(): ParserParseRequest {
      return ParserParseRequestFactory(
        {},
        ParserDocument["Юр лицо: Декларация о рисках"]
      );
    },

    isOtherShow(): boolean {
      if (this.files.length) {
        return true;
      }

      return !this.isReadonly;
    },

    isAddtionalUpload(): boolean {
      return this.files.length < FILES_LENGTH && !this.isReadonly;
    },

    isReadonly(): boolean {
      return this.userRepo.isReadonlyKyb;
    },

    isDisabled(): boolean {
      return Object.values(this.documents).some((document) => !document.id);
    },

    isContinueDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", VerificationKybComponent.kybCompany);
    },

    handleContinue(): void {
      if (this.isDisabled) {
        this.isValidate = true;

        return;
      }

      this.$emit("update:component", VerificationKybComponent.kybCompany);
    },

    async handleUploadFile({
      file,
      document,
    }: {
      file: FileParams;
      document: KybUploadFileResponse;
    }): Promise<void> {
      try {
        this.isLoading = true;
        this.documents[document.name].isUpload = true;

        const uploadFile = await this.kybRepository.uploadFile(
          KybUploadFileRequestFactory({
            file: file.file,
            name: document.name,
            type: file.type,
          })
        );

        this.documents[document.name] =
          KybUploadFileResponseFactory(uploadFile);
      } catch (error) {
        this.documents[document.name] = KybUploadFileResponseFactory({
          name: document.name,
        });
      } finally {
        this.isLoading = false;
        this.documents[document.name].isUpload = false;
      }
    },

    async handleRemoveFile(document: KybUploadFileResponse): Promise<void> {
      try {
        this.isLoading = true;
        this.documents[document.name].isRemove = true;

        await this.kybRepository.deleteFile(document.id!);

        this.documents[document.name] = KybUploadFileResponseFactory({
          name: document.name,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.documents[document.name].isRemove = false;
      }
    },

    async handleUploadAdditionalFile({
      file,
    }: {
      file: FileParams;
    }): Promise<void> {
      try {
        this.isLoading = true;
        this.file.isUpload = true;

        this.files.push(
          await this.userRepository.upload(
            UserFileRequestFactory({
              file: file.file,
              name: file.name,
              fileFor: FileFor.kyb,
              type: file.type as FileType,
            })
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.file.isUpload = false;
      }
    },

    async handleRemoveAdditionalFile(
      document: UserFileResponse
    ): Promise<void> {
      try {
        this.isLoading = true;
        document.isRemove = true;

        await this.userRepository.delete(document.id!);

        this.files.splice(
          this.files.findIndex((file) => file.uuid === document.uuid),
          1
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        document.isRemove = false;
      }
    },
  },
});
