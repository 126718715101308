import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedSearch } from "@/components/shared";

import { SvgAttribute } from "@/shared/constants/constants";
import { HistoryTab } from "@/shared/constants/enums";
import { HistoryFilterRequest, Search } from "@/shared/constants/interfaces";
import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";
import { ApplicationHistoryResponse } from "@/shared/repository/modules/application/repo";

export interface HistoryHeaderTab {
  id: number;
  label: string;
  value: HistoryTab;
  acitve: boolean;
  isDisabled: boolean;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSearch,
  },
  props: {
    history: {
      type: <
        PropType<
          DepositWithdrawalHistoryResponse[] | ApplicationHistoryResponse[]
        >
      >Array,
      default: () => [],
    },
  },
  emits: {
    "update:history": null,
  },
  data() {
    return {
      isLoading: false,
      historyRepo: this.$projectServices.historyRepo,
      depositWithdrawalRepository:
        this.$projectServices.depositWithdrawalRepository,
      applicationRepository: this.$projectServices.applicationRepository,
    };
  },
  computed: {
    displayedTab(): HistoryTab {
      return this.historyRepo.tab;
    },

    displayedFilter(): HistoryFilterRequest {
      return this.historyRepo.filter;
    },

    displayedSearch(): Search {
      return this.historyRepo.search;
    },

    displayedTabs(): HistoryHeaderTab[] {
      return [
        {
          id: 0,
          label: "Пополнение/Вывод",
          value: HistoryTab.depositWithdrawal,
          acitve: this.displayedTab === HistoryTab.depositWithdrawal,
          isDisabled: this.isLoading,
        },
        {
          id: 1,
          label: "Заявки",
          value: HistoryTab.application,
          acitve: this.displayedTab === HistoryTab.application,
          isDisabled: this.isLoading,
        },
      ];
    },

    displayedIcons(): SvgAttribute {
      return SVG;
    },

    hasFilter(): boolean {
      const {
        amount_from,
        amount_to,
        date_from,
        date_to,
        status,
        currency_id,
        type,
      } = this.displayedFilter;

      return (
        !!amount_from ||
        !!amount_to ||
        !!date_from ||
        !!date_to ||
        !!status ||
        !!currency_id ||
        !!type
      );
    },

    isFilterActive(): boolean {
      return this.historyRepo.isFilter && this.hasFilter;
    },
  },
  methods: {
    async handleTab({ value }: HistoryHeaderTab): Promise<void> {
      if (this.displayedTab !== value) {
        this.historyRepo.CLEAR_PAGE();
        this.historyRepo.CLEAR_TYPE();

        this.historyRepo.UPDATE_TAB(value);

        await this.handleFetch();
      }
    },

    handleFilter(): void {
      this.historyRepo.UPDATE_IS_FILTER(true);
    },

    async handleClearFilter(): Promise<void> {
      this.historyRepo.CLEAR_FILTER();

      await this.handleFetch();
    },

    handleSearch(value: string): void {
      this.historyRepo.UPDATE_SEARCH_VALUE(value);
    },

    async handleFetch(): Promise<void> {
      try {
        this.isLoading = true;

        this.$emit("update:history", []);

        switch (this.displayedTab) {
          case HistoryTab.depositWithdrawal:
            this.$emit(
              "update:history",
              await this.depositWithdrawalRepository.history(
                this.displayedFilter
              )
            );

            break;
          case HistoryTab.application:
            this.$emit(
              "update:history",
              await this.applicationRepository.history(this.displayedFilter)
            );

            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
