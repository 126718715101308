import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";
import { KybCompanyItem } from "./model";

import { UIButton } from "@/components/ui";
import { SharedCard } from "@/components/shared";

import { VerificationKybComponent } from "@/views/verification/model";
import {
  ModalIcon,
  ModalLabel,
  ModalTitle,
  SvgAttribute,
} from "@/shared/constants/constants";
import { KybCompanyFactory } from "@/shared/repository/modules/kyb/factory";
import { ModalFactory } from "@/shared/factory/modal";

enum VerificationLabel {
  "Отправлено на проверку" = "Отправлено на проверку",
  "Отправить на проверку" = "Отправить на проверку",
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedCard,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isLoading: false,
      userRepo: this.$projectServices.userRepo,
      userRepository: this.$projectServices.userRepository,
      kybRepository: this.$projectServices.kybRepository,
      kybCompany: KybCompanyFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.kybCompany = await this.kybRepository.company(
        this.userRepo.userInfo.id
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedList(): KybCompanyItem[] {
      const { isBasic, isParticipants, isFiles } = this.kybCompany;

      return [
        {
          id: 0,
          title: "Основная информация",
          label:
            "Информация о счете, адрес компании и декларация о происхождении средств",
          icon: isBasic ? SVG.check : SVG.time,
          component: VerificationKybComponent.kybBasicInformation,
          isPadding: false,
          isDisabled: this.isLoading,
        },
        {
          id: 1,
          title: "Участники организации",
          label:
            "Основная информация, идентификационные данные и страна проживания связанных сторон",
          icon: isParticipants.isSuccess
            ? SVG.check
            : isParticipants.isExpected
            ? SVG.expected
            : SVG.time,
          component: VerificationKybComponent.kybParticipants,
          isPadding: true,
          isDisabled: !isBasic || this.isLoading,
        },
        {
          id: 2,
          title: "Загрузка документов",
          label:
            "Предоставьте необходимые документы для подтверждения вашей компании",
          icon: isFiles ? SVG.check : SVG.time,
          component: VerificationKybComponent.kybDocuments,
          isPadding: false,
          isDisabled: isParticipants.isWaiting || this.isLoading,
        },
      ];
    },

    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedVerificationLabel(): VerificationLabel {
      return this.isReadonly
        ? VerificationLabel["Отправлено на проверку"]
        : VerificationLabel["Отправить на проверку"];
    },

    isReadonly(): boolean {
      return this.userRepo.isReadonlyKyb;
    },

    isVerificationDisabled(): boolean {
      const { isBasic, isParticipants, isFiles } = this.kybCompany;

      return (
        !isBasic ||
        !isParticipants.isSuccess ||
        !isFiles ||
        this.isLoading ||
        this.isReadonly
      );
    },
  },
  methods: {
    handleContinue({ component }: KybCompanyItem): void {
      this.$emit("update:component", component);
    },

    async handleVerification(): Promise<void> {
      try {
        this.isLoading = true;

        await this.kybRepository.verification();

        const userInfo = await this.userRepository.userInfo();

        this.$projectServices.userRepo.UPDATE_USER_INFO(userInfo);

        this.$projectServices.modalRepo.UPDATE_MODAL(
          ModalFactory({
            title: ModalTitle.application,
            label: ModalLabel.application,
            icon: ModalIcon.application,
            callback: () => {},
          })
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
