import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WalletApplicationsRow = _resolveComponent("WalletApplicationsRow")!
  const _component_WalletCard = _resolveComponent("WalletCard")!

  return (_openBlock(), _createBlock(_component_WalletCard, {
    class: _normalizeClass(_ctx.b()),
    title: "Заявки",
    tab: "application"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('table'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('columns'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedColumns, (column) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(_ctx.b('column')),
              key: column.id
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('column-label'))
              }, _toDisplayString(_ctx.$t(column.label)), 3)
            ], 2))
          }), 128))
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('rows'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedHistory, (row, rowIndex) => {
            return (_openBlock(), _createBlock(_component_WalletApplicationsRow, {
              class: _normalizeClass(_ctx.b('row')),
              key: rowIndex,
              row: row
            }, null, 8, ["class", "row"]))
          }), 128))
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}