import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedWindow, SharedVerification } from "@/components/shared";
import { UIButton } from "@/components/ui";

import { CODE_MAX_LENGTH } from "@/shared/constants/constants";
import { RequisitesType } from "@/shared/repository/modules/requisites/enums";
import { RequisitesVerificationRequestFactory } from "@/shared/repository/modules/requisites/factory";
import { ApplicationComponent } from "@/views/application/model";
import { RequisitesCryptoResponse } from "@/shared/repository/modules/requisites/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedVerification,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<ApplicationComponent>>String,
      default: ApplicationComponent.crypto,
    },
    requisites: {
      type: <PropType<RequisitesCryptoResponse[]>>Array,
      default: () => [],
    },
    isVerification: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:component": null,
    "update:requisites": null,
    "update:isVerification": null,
  },
  data() {
    return {
      isError: false,
      isLoading: false,
      code: "",
      userRepo: this.$projectServices.userRepo,
      requisitesRepository: this.$projectServices.requisitesRepository,
    };
  },
  computed: {
    isConfirmDisabled(): boolean {
      return this.isLoading || this.code.length !== CODE_MAX_LENGTH;
    },
  },
  methods: {
    async handleVerification(): Promise<void> {
      try {
        this.isError = false;
        this.isLoading = true;

        await this.requisitesRepository.verification(
          RequisitesVerificationRequestFactory({
            code: this.code,
            type: RequisitesType.crypto,
          })
        );

        const requisites = await this.requisitesRepository.cryptoList(
          this.userRepo.userInfo.id
        );

        this.$emit("update:requisites", requisites);
        this.$emit("update:isVerification", false);
        this.$emit("update:component", ApplicationComponent.form);
      } catch (error) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
