import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import {
  LoginPage,
  RegistrationPage,
  ForgotPage,
  ApplicationPage,
  HistoryPage,
  SettingsPage,
  VerificationPage,
  AdminUsersPage,
  AdminConfigPage,
  AdminKycPage,
  AdminApplicationPage,
  WalletPage,
} from "@/views";

import { Layout, RoutesName, RoutesTo } from "@/shared/constants/enums";
import { $projectServices } from "@/shared/repository/instances/project-services";

const routes: Array<RouteRecordRaw> = [
  {
    path: RoutesTo.login,
    name: RoutesName.login,
    component: LoginPage,
    meta: {
      layout: Layout.auth,
    },
  },
  {
    path: RoutesTo.registration,
    name: RoutesName.registration,
    component: RegistrationPage,
    meta: {
      layout: Layout.auth,
    },
  },
  {
    path: RoutesTo.forgot,
    name: RoutesName.forgot,
    component: ForgotPage,
    meta: {
      layout: Layout.auth,
    },
  },
  {
    path: RoutesTo.wallet,
    name: RoutesName.wallet,
    component: WalletPage,
    meta: {
      layout: Layout.default,
      isAuth: true,
      isVerification: true,
    },
  },
  {
    path: RoutesTo.application,
    name: RoutesName.application,
    component: ApplicationPage,
    meta: {
      layout: Layout.default,
      isAuth: true,
      isVerification: true,
    },
  },
  {
    path: RoutesTo.history,
    name: RoutesName.history,
    component: HistoryPage,
    meta: {
      layout: Layout.default,
      isAuth: true,
      isVerification: true,
    },
  },
  {
    path: RoutesTo.settings,
    name: RoutesName.settings,
    component: SettingsPage,
    meta: {
      layout: Layout.default,
      isAuth: true,
      isVerification: true,
    },
  },
  {
    path: RoutesTo.verification,
    name: RoutesName.verification,
    component: VerificationPage,
    meta: {
      layout: Layout.default,
      isAuth: true,
      isVerified: true,
    },
  },
  {
    path: RoutesTo.adminUsers,
    name: RoutesName.adminUsers,
    component: AdminUsersPage,
    meta: {
      layout: Layout.admin,
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: RoutesTo.adminKyc,
    name: RoutesName.adminKyc,
    component: AdminKycPage,
    meta: {
      layout: Layout.admin,
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: RoutesTo.adminApplication,
    name: RoutesName.adminApplication,
    component: AdminApplicationPage,
    meta: {
      layout: Layout.admin,
      isAuth: true,
      isAdmin: true,
    },
  },
  {
    path: RoutesTo.adminConfig,
    name: RoutesName.adminConfig,
    component: AdminConfigPage,
    meta: {
      layout: Layout.admin,
      isAuth: true,
      isAdmin: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuth = to.meta.isAuth;
  const isAdmin = to.meta.isAdmin;
  const isVerification = to.meta.isVerification;
  const isVerified = to.meta.isVerified;

  try {
    const userInfo = await $projectServices.userRepository.userInfo();

    $projectServices.userRepo.UPDATE_USER_INFO(userInfo);

    if (userInfo.email_verified_at) {
      if (isAdmin) {
        const admins = await $projectServices.adminUsersRepository.list();

        if (!admins[userInfo.email]) {
          next({ name: RoutesName.wallet });
        }
      }

      if (isVerification && !$projectServices.userRepo.isVerified) {
        next({ name: RoutesName.verification });
      }

      if (isVerified && $projectServices.userRepo.isVerified) {
        next({ name: RoutesName.wallet });
      }

      $projectServices.userRepo.UPDATE_IS_AUTHENTICATED(true);

      next();
    } else {
      await $projectServices.authRepository.logout();

      $projectServices.userRepo.UPDATE_IS_AUTHENTICATED(false);
      $projectServices.userRepo.setLanguage();
    }

    next({ name: RoutesName.login });
  } catch (error) {
    if (isAuth) {
      if (
        (error as unknown as { message: string }).message !== "Unauthenticated."
      ) {
        await $projectServices.authRepository.logout();
      }

      $projectServices.userRepo.UPDATE_IS_AUTHENTICATED(false);
      $projectServices.userRepo.setLanguage();

      next({ name: RoutesName.login });
    }

    $projectServices.userRepo.UPDATE_IS_AUTHENTICATED(false);
    $projectServices.userRepo.setLanguage();

    next();
  }
});

export default router;
