import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("input", {
      class: _normalizeClass(_ctx.b('field', { error: _ctx.isErrorShow })),
      type: "text",
      value: _ctx.value,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeValue && _ctx.changeValue(...args)))
    }, null, 42, _hoisted_1),
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.b('label'))
    }, _toDisplayString(_ctx.label), 3)
  ], 2))
}