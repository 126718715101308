import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { SharedWindow } from "@/components/shared";
import { UIButton } from "@/components/ui";
import { ApplicationHistoryResponse } from "@/shared/repository/modules/application/repo";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";
import { ApplicationStatus } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    UIButton,
  },
  props: {
    application: {
      type: <PropType<ApplicationHistoryResponse>>Object,
      required: true,
    },
  },
  emits: {
    application: null,
  },
  computed: {
    displayedChevronIcon(): string {
      return SVG.chevron;
    },

    displayedAmountFrom(): string {
      return formatFiatCurrency(this.application.amount);
    },

    displayedAmountTo(): string {
      const { amount, commissions, execution_price } = this.application;

      return formatFiatCurrency((amount - commissions) * execution_price);
    },

    displayedCommissions(): string {
      return `~ ${formatFiatCurrency(
        this.application.commissions
      )} ${this.application.currency_from.name.toUpperCase()}`;
    },

    displayedExecutionPrice(): string {
      return `${formatFiatCurrency(
        this.application.execution_price
      )} ${this.application.currency_to.name.toUpperCase()}`;
    },

    displayedStatus(): string {
      switch (this.application.status) {
        case ApplicationStatus.pending:
          return "Создано";
        case ApplicationStatus.waiting:
          return "Ожидание подтверждения";
        case ApplicationStatus.inWork:
          return "В работе";
        case ApplicationStatus.reject:
          return "Отклонено";
        case ApplicationStatus.completed:
          return "Выполнено";
        default:
          return "Создано";
      }
    },
  },
  methods: {
    handleClose(): void {
      this.$emit("application", null);
    },
  },
});
