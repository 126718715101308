import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "wallet-history-row";

export const SVG: SvgAttribute = {
  withdrawal: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0238 2.53011C12.9975 2.52724 12.9707 2.52577 12.9436 2.52577L6.59383 2.52577C6.18943 2.52577 5.86161 2.8536 5.86161 3.25799C5.86161 3.66239 6.18943 3.99021 6.59383 3.99021L11.2272 3.99021L4.71991 10.4975C4.42702 10.7904 4.42702 11.2652 4.71991 11.5581C5.01281 11.851 5.48768 11.851 5.78058 11.5581L12.2963 5.04247L12.2963 9.69113C12.2963 10.0955 12.6241 10.4233 13.0285 10.4233C13.4329 10.4233 13.7607 10.0955 13.7607 9.69113L13.7607 3.26232C13.7607 2.85793 13.4329 2.5301 13.0285 2.5301C13.0269 2.5301 13.0254 2.5301 13.0238 2.53011Z" fill="#415C6B" fill-opacity="0.4"/>
    <rect x="1.5" y="15.0339" width="15" height="1.5" rx="0.75" fill="#415C6B" fill-opacity="0.4"/>
  </svg>`,
  deposit: `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.23056 11.3979C5.25882 11.4012 5.28757 11.4029 5.31671 11.4029L11.6665 11.4029C12.0709 11.4029 12.3987 11.0751 12.3987 10.6707C12.3987 10.2663 12.0709 9.93844 11.6665 9.93844L7.0334 9.93844L13.5407 3.43119C13.8336 3.1383 13.8336 2.66342 13.5407 2.37053C13.2478 2.07764 12.7729 2.07764 12.48 2.37053L5.96435 8.88618L5.96435 4.23684C5.96435 3.83245 5.63652 3.50462 5.23213 3.50462C4.82773 3.50462 4.49991 3.83245 4.49991 4.23684L4.49991 10.6657C4.49991 11.0695 4.82689 11.397 5.23056 11.3979Z" fill="#415C6B" fill-opacity="0.4"/>
    <rect x="1.5" y="14.6587" width="15" height="1.5" rx="0.75" fill="#415C6B" fill-opacity="0.4"/>
  </svg>`,
  pending: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M6.89453 0.996094C8.95187 0.989583 11.0091 0.996094 13.0664 1.01563C14.3086 1.0202 15.4805 1.30665 16.582 1.875C17.6316 2.5461 18.3152 3.49011 18.6328 4.70703C18.7661 5.22035 18.8573 5.74117 18.9062 6.26953C18.9492 7.50594 18.9687 8.74289 18.9648 9.98047C18.9687 11.218 18.9492 12.455 18.9062 13.6914C18.8393 14.7144 18.5659 15.678 18.0859 16.582C17.4148 17.6316 16.4708 18.3151 15.2539 18.6328C14.7405 18.7661 14.2197 18.8573 13.6914 18.9062C12.455 18.9492 11.218 18.9687 9.98047 18.9648C8.74289 18.9687 7.50594 18.9492 6.26953 18.9062C5.24641 18.8393 4.28285 18.5659 3.37891 18.0859C2.18451 17.3076 1.46185 16.2074 1.21094 14.7852C1.09949 14.2175 1.03439 13.6446 1.01563 13.0664C0.989583 11.0091 0.989583 8.95183 1.01563 6.89453C1.03456 5.86933 1.22987 4.87976 1.60156 3.92578C2.21873 2.56691 3.25388 1.70103 4.70703 1.32813C5.43176 1.14931 6.1609 1.03863 6.89453 0.996094ZM6.85547 2.28516C8.93883 2.27864 11.0222 2.28516 13.1055 2.30469C14.0839 2.30761 15.0084 2.52896 15.8789 2.96875C16.6463 3.45071 17.1476 4.1343 17.3828 5.01953C17.4988 5.44226 17.577 5.87195 17.6172 6.30859C17.6798 8.56062 17.6928 10.8132 17.6562 13.0664C17.6393 13.8712 17.4961 14.6524 17.2266 15.4102C16.7807 16.4285 16.019 17.0861 14.9414 17.3828C14.5189 17.4988 14.0892 17.5769 13.6523 17.6172C11.3873 17.68 9.12168 17.693 6.85547 17.6562C6.06414 17.635 5.2959 17.4918 4.55078 17.2266C3.53237 16.7807 2.87482 16.019 2.57813 14.9414C2.41074 14.3277 2.31959 13.7027 2.30469 13.0664C2.27865 11.0091 2.27865 8.95183 2.30469 6.89453C2.30288 5.82094 2.56329 4.81832 3.08594 3.88672C3.5711 3.22355 4.21563 2.78736 5.01953 2.57812C5.6277 2.4203 6.23969 2.32264 6.85547 2.28516Z" fill="#A1A2AB"/>
    <path d="M9.1667 4.8747C9.1667 4.48366 9.46649 4.16666 9.83631 4.16666C10.2061 4.16666 10.5059 4.48366 10.5059 4.8747V9.95628C10.5059 10.3473 10.2061 10.6643 9.83631 10.6643C9.46649 10.6643 9.1667 10.3473 9.1667 9.95628V4.8747Z" fill="#A1A2AB"/>
    <path d="M13.8318 12.012C14.152 12.2075 14.2618 12.6405 14.0769 12.9792C13.892 13.3178 13.4824 13.4339 13.1622 13.2384L9.74661 10.8286C9.16638 10.4167 9.17212 10.0506 9.16638 9.58333C9.35129 9.24468 10.096 9.40674 10.4162 9.60226L13.8318 12.012Z" fill="#A1A2AB"/>
  </svg>`,
  waiting: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.969" fill-rule="evenodd" clip-rule="evenodd" d="M8.20096 2.41138C11.3833 2.40291 14.5656 2.41138 17.7478 2.43677C18.7125 2.45851 19.6604 2.59393 20.5916 2.84302C22.4194 3.48597 23.6297 4.73012 24.2224 6.57544C24.3788 7.14114 24.4804 7.71665 24.5271 8.302C24.6958 11.4166 24.6958 14.5312 24.5271 17.6458C24.4151 20.0647 23.2894 21.8167 21.1502 22.9016C20.052 23.2962 18.9179 23.4994 17.7478 23.511C15.6997 23.5449 13.6515 23.5449 11.6033 23.511C11.0655 23.2349 10.9216 22.8202 11.1717 22.2668C11.2878 22.1164 11.4317 22.0064 11.6033 21.9368C13.6516 21.9104 15.6998 21.8765 17.7478 21.8352C21.0711 21.8803 22.7891 20.2469 22.9021 16.9348C22.9786 14.7518 22.9956 12.5682 22.9529 10.384C22.9525 9.46484 22.8848 8.55078 22.7498 7.64185C21.5226 8.86908 20.2954 10.0963 19.0681 11.3235C18.3026 12.0553 17.507 12.7494 16.6814 13.4055C14.2101 15.2675 11.7387 15.2675 9.26737 13.4055C8.44177 12.7494 7.64618 12.0553 6.88065 11.3235C5.65342 10.0963 4.42622 8.86908 3.19901 7.64185C3.12736 8.09659 3.07657 8.55362 3.04666 9.01294C2.99529 10.4174 2.96143 11.8224 2.9451 13.2278C2.7428 13.7224 2.37887 13.9002 1.8533 13.761C1.58632 13.6464 1.40859 13.4517 1.3201 13.177C1.27661 11.5503 1.31046 9.92528 1.42166 8.302C1.53374 5.88304 2.65939 4.1311 4.79862 3.04614C5.90631 2.65852 7.04041 2.44693 8.20096 2.41138ZM10.6892 4.03638C13.0424 4.02942 15.3953 4.05481 17.7478 4.11255C18.6132 4.1346 19.4596 4.27001 20.2869 4.5188C21.0151 4.82388 21.6075 5.29783 22.0642 5.94067C20.284 7.75478 18.4728 9.53213 16.6306 11.2727C15.9308 11.8852 15.1691 12.41 14.3455 12.8469C13.2643 13.2719 12.2149 13.2042 11.1971 12.6438C10.5423 12.226 9.916 11.769 9.31815 11.2727C7.47596 9.53213 5.66479 7.75478 3.88455 5.94067C4.40484 5.19785 5.09884 4.69004 5.96659 4.41724C6.46724 4.2901 6.97505 4.20546 7.49002 4.16333C8.5642 4.11375 9.6306 4.07144 10.6892 4.03638Z" fill="#FCCA13"/>
  <path opacity="0.957" fill-rule="evenodd" clip-rule="evenodd" d="M0.786919 16.4778C2.03964 16.4693 3.29224 16.4778 4.54473 16.5032C4.99292 16.6797 5.18757 17.0098 5.12872 17.4934C5.05254 17.8404 4.84095 18.052 4.49395 18.1282C3.29212 18.1621 2.09031 18.1621 0.888481 18.1282C0.255679 17.8894 0.0779443 17.4577 0.355278 16.8333C0.479244 16.6833 0.623124 16.5648 0.786919 16.4778Z" fill="#FCCA13"/>
  <path opacity="0.963" fill-rule="evenodd" clip-rule="evenodd" d="M0.786919 19.7278C3.12292 19.7193 5.45884 19.7278 7.79473 19.7532C8.18133 19.882 8.38445 20.1528 8.40411 20.5657C8.36374 20.9861 8.1437 21.2569 7.74395 21.3782C5.45879 21.4121 3.17364 21.4121 0.888481 21.3782C0.255679 21.1394 0.0779443 20.7077 0.355278 20.0833C0.479244 19.9333 0.623124 19.8148 0.786919 19.7278Z" fill="#FCCA13"/>
  </svg>`,
  completed: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.967" fill-rule="evenodd" clip-rule="evenodd" d="M6.89453 0.996094C8.95187 0.989583 11.0091 0.996094 13.0664 1.01563C14.3086 1.0202 15.4805 1.30665 16.582 1.875C17.6316 2.5461 18.3152 3.49011 18.6328 4.70703C18.7661 5.22035 18.8573 5.74117 18.9062 6.26953C18.9492 7.50594 18.9687 8.74289 18.9648 9.98047C18.9687 11.218 18.9492 12.455 18.9062 13.6914C18.8393 14.7144 18.5659 15.678 18.0859 16.582C17.4148 17.6316 16.4708 18.3151 15.2539 18.6328C14.7405 18.7661 14.2197 18.8573 13.6914 18.9062C12.455 18.9492 11.218 18.9687 9.98047 18.9648C8.74289 18.9687 7.50594 18.9492 6.26953 18.9062C5.24641 18.8393 4.28285 18.5659 3.37891 18.0859C2.18451 17.3076 1.46185 16.2074 1.21094 14.7852C1.09949 14.2175 1.03439 13.6446 1.01563 13.0664C0.989583 11.0091 0.989583 8.95183 1.01563 6.89453C1.03456 5.86933 1.22987 4.87976 1.60156 3.92578C2.21873 2.56691 3.25388 1.70103 4.70703 1.32813C5.43176 1.14931 6.1609 1.03863 6.89453 0.996094ZM6.85547 2.28516C8.93883 2.27864 11.0222 2.28516 13.1055 2.30469C14.0839 2.30761 15.0084 2.52896 15.8789 2.96875C16.6463 3.45071 17.1476 4.1343 17.3828 5.01953C17.4988 5.44226 17.577 5.87195 17.6172 6.30859C17.6798 8.56062 17.6928 10.8132 17.6562 13.0664C17.6393 13.8712 17.4961 14.6524 17.2266 15.4102C16.7807 16.4285 16.019 17.0861 14.9414 17.3828C14.5189 17.4988 14.0892 17.5769 13.6523 17.6172C11.3873 17.68 9.12168 17.693 6.85547 17.6562C6.06414 17.635 5.2959 17.4918 4.55078 17.2266C3.53237 16.7807 2.87482 16.019 2.57813 14.9414C2.41074 14.3277 2.31959 13.7027 2.30469 13.0664C2.27865 11.0091 2.27865 8.95183 2.30469 6.89453C2.30288 5.82094 2.56329 4.81832 3.08594 3.88672C3.5711 3.22355 4.21563 2.78736 5.01953 2.57812C5.6277 2.4203 6.23969 2.32264 6.85547 2.28516Z" fill="#73D264"/>
    <path opacity="0.959" fill-rule="evenodd" clip-rule="evenodd" d="M13.3398 6.97266C14.0451 6.98125 14.286 7.3198 14.0625 7.98828C12.4143 9.64953 10.7541 11.2966 9.08201 12.9297C8.83494 13.0209 8.60057 12.9948 8.37889 12.8516C7.5651 12.0378 6.75127 11.2239 5.93748 10.4102C5.74111 10.0892 5.76064 9.7832 5.99607 9.49219C6.26158 9.3284 6.53502 9.31539 6.81639 9.45312C7.47393 10.1107 8.1315 10.7682 8.78904 11.4258C10.2278 9.98699 11.6667 8.54816 13.1054 7.10938C13.1809 7.05238 13.259 7.00684 13.3398 6.97266Z" fill="#73D264"/>
  </svg>`,
  reject: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.966" fill-rule="evenodd" clip-rule="evenodd" d="M7.32422 0.996094C9.09512 0.989583 10.8659 0.996094 12.6367 1.01563C13.3559 1.10849 13.9939 1.38193 14.5508 1.83594C15.7422 3.02734 16.9336 4.21875 18.125 5.41016C18.5797 5.96824 18.8531 6.60629 18.9453 7.32422C18.9714 9.09504 18.9714 10.8659 18.9453 12.6367C18.8531 13.3546 18.5797 13.9927 18.125 14.5508C16.9336 15.7422 15.7422 16.9336 14.5508 18.125C13.9927 18.5797 13.3547 18.8531 12.6367 18.9453C10.8659 18.9714 9.09504 18.9714 7.32422 18.9453C6.60629 18.8531 5.96824 18.5797 5.41016 18.125C4.21875 16.9336 3.02735 15.7422 1.83594 14.5508C1.38124 13.9927 1.1078 13.3546 1.01563 12.6367C0.989583 10.8659 0.989583 9.09504 1.01563 7.32422C1.1077 6.60645 1.38114 5.96844 1.83594 5.41016C3.02735 4.21875 4.21875 3.02734 5.41016 1.83594C5.97563 1.38378 6.61367 1.10383 7.32422 0.996094ZM7.40235 2.28516C9.12114 2.27865 10.8399 2.28516 12.5586 2.30469C13.0001 2.38225 13.3907 2.56455 13.7305 2.85156C14.8698 3.9909 16.0091 5.1302 17.1484 6.26953C17.4127 6.60293 17.582 6.98051 17.6563 7.40234C17.6823 9.12109 17.6823 10.8398 17.6563 12.5586C17.5787 13.0001 17.3964 13.3907 17.1094 13.7305C15.97 14.8698 14.8307 16.0091 13.6914 17.1484C13.358 17.4127 12.9804 17.582 12.5586 17.6562C10.8398 17.6823 9.1211 17.6823 7.40235 17.6562C6.96086 17.5787 6.57024 17.3964 6.23047 17.1094C5.09114 15.97 3.95184 14.8307 2.8125 13.6914C2.54822 13.358 2.37895 12.9804 2.30469 12.5586C2.27865 10.8398 2.27865 9.12109 2.30469 7.40234C2.38226 6.96086 2.56455 6.57023 2.85156 6.23047C3.9909 5.09113 5.1302 3.95184 6.26953 2.8125C6.61051 2.55077 6.98813 2.37498 7.40235 2.28516Z" fill="#F86E6E"/>
    <path opacity="0.961" fill-rule="evenodd" clip-rule="evenodd" d="M6.89453 6.42578C7.08641 6.40871 7.26867 6.44125 7.44141 6.52344C8.2818 7.37035 9.12812 8.21019 9.98047 9.04297C10.8328 8.21019 11.6791 7.37035 12.5195 6.52344C13.0845 6.29801 13.4231 6.48027 13.5352 7.07031C13.5345 7.20246 13.502 7.32617 13.4375 7.44141C12.5906 8.2818 11.7507 9.12812 10.918 9.98047C11.7507 10.8328 12.5906 11.6791 13.4375 12.5195C13.6629 13.0845 13.4807 13.4231 12.8906 13.5352C12.7585 13.5345 12.6348 13.502 12.5195 13.4375C11.6791 12.5906 10.8328 11.7507 9.98047 10.918C9.12812 11.7507 8.2818 12.5906 7.44141 13.4375C6.87641 13.6629 6.53785 13.4807 6.42578 12.8906C6.42645 12.7585 6.45898 12.6348 6.52344 12.5195C7.37035 11.6791 8.2102 10.8328 9.04297 9.98047C8.2102 9.12812 7.37035 8.2818 6.52344 7.44141C6.32035 6.97601 6.44402 6.63746 6.89453 6.42578Z" fill="#F86E6E"/>
  </svg>`,
  in_work: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.964" fill-rule="evenodd" clip-rule="evenodd" d="M7.5918 2.66602C8.25429 2.70898 8.88058 2.88671 9.4707 3.19922C13.2285 5.31512 16.9863 7.43097 20.7441 9.54688C22.4158 10.9288 22.8813 12.647 22.1406 14.7012C21.8586 15.3385 21.4439 15.8717 20.8965 16.3008C17.1639 18.4295 13.423 20.5454 9.67383 22.6484C7.70981 23.682 5.95786 23.4027 4.41797 21.8105C3.91924 21.1852 3.61455 20.4742 3.50391 19.6777C3.47005 15.209 3.47005 10.7402 3.50391 6.27148C3.95944 4.02961 5.32208 2.82779 7.5918 2.66602ZM7.08399 4.39258C7.74648 4.31277 8.35585 4.45664 8.91211 4.82422C12.5007 6.83856 16.0892 8.85285 19.6777 10.8672C20.6154 11.5638 20.9624 12.4863 20.7188 13.6348C20.5314 14.3131 20.1335 14.8293 19.5254 15.1836C15.7572 17.2961 11.9825 19.3951 8.20117 21.4805C7.09714 21.7331 6.20847 21.403 5.53516 20.4902C5.35631 20.1907 5.23783 19.8691 5.17969 19.5254C5.14582 15.1582 5.14582 10.791 5.17969 6.42383C5.4078 5.35803 6.04256 4.68093 7.08399 4.39258Z" fill="#36A1EA"/>
  </svg>`,
};
