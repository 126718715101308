import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedDate,
  SharedRadio,
  SharedCountry,
  SharedValidate,
} from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { VerificationKybComponent } from "@/views/verification/model";
import {
  KybBasicInfoFieldsFactory,
  KybBasicInfoRequestFactory,
  KybBasicInfoResponseFactory,
} from "@/shared/repository/modules/kyb/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedDate,
    SharedRadio,
    SharedCountry,
    SharedValidate,
    UIInput,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      userRepo: this.$projectServices.userRepo,
      kybRepository: this.$projectServices.kybRepository,
      kybBasicInfo: KybBasicInfoResponseFactory(),
      kybBasicInfoFields: KybBasicInfoFieldsFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      this.kybBasicInfo = await this.kybRepository.basicInfo(
        this.userRepo.userInfo.id
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    isReadonly(): boolean {
      return this.userRepo.isReadonlyKyb;
    },

    isDisabled(): boolean {
      return Object.values(this.kybBasicInfoFields).some((field) => !field);
    },

    isContinueDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", VerificationKybComponent.kybCompany);
    },

    async handleContinue(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.isDisabled) {
          this.isValidate = true;

          return;
        }

        if (!this.isReadonly) {
          await this.kybRepository.basicInfoUpdate(
            KybBasicInfoRequestFactory(this.kybBasicInfo)
          );
        }

        this.$emit(
          "update:component",
          VerificationKybComponent.kybRegistrationInformation
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
