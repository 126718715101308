import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled", "onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSearch = _resolveComponent("SharedSearch")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('tabs'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedTabs, (tab) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(_ctx.b('tab', { active: tab.acitve })),
          key: tab.id,
          disabled: tab.isDisabled,
          onClick: ($event: any) => (_ctx.handleTab(tab))
        }, _toDisplayString(_ctx.$t(tab.label)), 11, _hoisted_1))
      }), 128))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('block'))
    }, [
      _createVNode(_component_SharedSearch, {
        search: _ctx.displayedSearch.value,
        "onUpdate:search": _ctx.handleSearch
      }, null, 8, ["search", "onUpdate:search"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('group'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('filter'))
        }, [
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.b('button', { filter: true, active: _ctx.isFilterActive })),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFilter && _ctx.handleFilter(...args)))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('button-icon')),
              innerHTML: _ctx.displayedIcons.filter
            }, null, 10, _hoisted_2)
          ], 2),
          (_ctx.hasFilter)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(_ctx.b('button', { 'filter-clear': true })),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClearFilter && _ctx.handleClearFilter(...args)))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('button-icon')),
                  innerHTML: _ctx.displayedIcons.clear
                }, null, 10, _hoisted_3),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('button-label', { 'filter-clear': true }))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t("Очистить")) + " ", 1),
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.b('button-label-sub'))
                  }, _toDisplayString(_ctx.$t("Очистить фильтр")), 3)
                ], 2)
              ], 2))
            : _createCommentVNode("", true)
        ], 2)
      ], 2)
    ], 2)
  ], 2))
}