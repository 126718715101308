<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('date-time')">
      <p :class="b('label')">
        {{ row.created_at }}
      </p>
    </div>

    <div :class="b('operation')">
      <div :class="b('group')">
        <p :class="b('label', { from: true })">
          {{ displayedAmountFrom }}
        </p>

        <p :class="b('label')">
          {{ row.currency_from.name.toUpperCase() }}
        </p>
      </div>

      <span
        :class="b('icon', { chevron: true })"
        v-html="displayedSvg.chevron"
      ></span>

      <div :class="b('group')">
        <p :class="b('label', { to: true })">
          {{ displayedAmountTo }}
        </p>

        <p :class="b('label')">
          {{ row.currency_to.name.toUpperCase() }}
        </p>
      </div>

      <span
        :class="b('icon', { type: true })"
        v-html="displayedSvg[row.status]"
      ></span>
    </div>
  </div>
</template>
