import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIInput, UIButton } from "@/components/ui";
import { RequisitesCryptoRequestFactory } from "@/shared/repository/modules/requisites/factory";
import { ApplicationComponent } from "@/views/application/model";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIInput,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<ApplicationComponent>>String,
      default: ApplicationComponent.form,
    },
    isVerification: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:component": null,
    "update:isVerification": null,
  },
  data() {
    return {
      isLoading: false,
      requisitesCryptoRequest: RequisitesCryptoRequestFactory(),
      requisitesRepository: this.$projectServices.requisitesRepository,
      userRepo: this.$projectServices.userRepo,
    };
  },
  computed: {
    displayedChevronIcon(): string {
      return SVG.chevron;
    },

    isConfirmDisabled(): boolean {
      const { name, coin, network, address } = this.requisitesCryptoRequest;

      return !name || !coin || !network || !address || this.isLoading;
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", ApplicationComponent);
    },

    async handleConfirm(): Promise<void> {
      try {
        this.isLoading = true;

        await this.requisitesRepository.crypto(
          this.requisitesCryptoRequest,
          this.userRepo.userInfo.id
        );

        this.$emit("update:isVerification", true);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
