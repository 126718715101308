import { AxiosInstance } from "axios";

import { AdminApplicationUrlGenerator } from "./url-generator";
import {
  AdminApplicationListResponse,
  AdminApplicationShowResponse,
  AdminApplicationUpdateRequest,
} from "./repo";

import { ResponseData } from "@/shared/constants/interfaces";
import {
  AdminApplicationListResponseFactory,
  AdminApplicationShowResponseFactory,
} from "./factory";
import { ApplicationStatus } from "@/shared/constants/enums";

export interface AdminApplicationProjectRepository {
  update(
    id: number,
    status: ApplicationStatus,
    payload: AdminApplicationUpdateRequest
  ): Promise<void>;
  delete(id: number): Promise<void>;
  show(id: number): Promise<AdminApplicationShowResponse>;
  list(): Promise<AdminApplicationListResponse[]>;
}

export class AdminApplicationHttpRepo
  implements AdminApplicationProjectRepository
{
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: AdminApplicationUrlGenerator
  ) {}

  async update(
    id: number,
    status: ApplicationStatus,
    payload: AdminApplicationUpdateRequest
  ): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.update(id, status), payload);
  }

  async delete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.delete(id));
  }

  async show(id: number): Promise<AdminApplicationShowResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminApplicationShowResponse>
    >(this.urlGenerator.show(id));

    return AdminApplicationShowResponseFactory(data.data);
  }

  async list(): Promise<AdminApplicationListResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<AdminApplicationListResponse[]>
    >(this.urlGenerator.list());

    return data.data.map((item) => AdminApplicationListResponseFactory(item));
  }
}
