import { ApplicationStatus } from "@/shared/constants/enums";
import { AdminApplicationType } from "../admin/application/enums";
import { AdminCurrencyType } from "../admin/currency/enums";
import { AdminRequisitesType } from "../admin/requisites/enums";
import {
  ApplicationCommissionRequest,
  ApplicationCommissionResponse,
  ApplicationHistoryResponse,
  ApplicationShowResponse,
  ApplicationStoreRequest,
} from "./repo";
import { UserResponseFactory } from "../user/factory";
import moment from "moment";
import { AdminCurrencyShowResponseFactory } from "../admin/currency/factory";

export const ApplicationStoreRequestFactory = (
  payload: Partial<ApplicationStoreRequest> = {}
): ApplicationStoreRequest => {
  return {
    amount: payload.amount ?? 0,
    currency_id_from: payload.currency_id_from ?? 0,
    currency_type_from: payload.currency_type_from ?? "",
    currency_id_to: payload.currency_id_to ?? 0,
    currency_type_to: payload.currency_type_to ?? "",
    user_requisite_type_from:
      payload.user_requisite_type_from ?? AdminRequisitesType.crypto,
    user_requisite_type_to:
      payload.user_requisite_type_to ?? AdminRequisitesType.crypto,
    user_requisite_id_to: payload.user_requisite_id_to ?? 0,
    user_requisite_id_from: payload.user_requisite_id_from ?? 0,
    commissions: payload.commissions ?? 0,
    execution_price: payload.execution_price ?? 0,
    transaction_volume: payload.transaction_volume ?? 0,
    max_amount: payload.max_amount ?? 0,
  };
};

export const ApplicationShowResponseFactory = (
  payload: Partial<ApplicationShowResponse> = {}
): ApplicationShowResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    app_type: payload.app_type ?? AdminApplicationType.deposit,
    currency_type: payload.currency_type ?? AdminCurrencyType.fiat,
    currency_id: payload.currency_id ?? 0,
    user_requisite_id: payload.user_requisite_id ?? 0,
    user_requisite_type:
      payload.user_requisite_type ?? AdminRequisitesType.bank,
    amount: payload.amount ?? 0,
    execution_price: payload.execution_price ?? 0,
    transaction_volume: payload.transaction_volume ?? 0,
    commissions: payload.commissions ?? 0,
    status: payload.status ?? ApplicationStatus.pending,
    stage: payload.stage ?? "",
    estimate_completion: payload.estimate_completion ?? "",
    deleted_at: payload.deleted_at ?? "",
    created_at: payload.created_at ?? "",
    updated_at: payload.updated_at ?? "",
    user: UserResponseFactory(payload.user ?? {}),
  };
};

export const ApplicationCommissionRequestFactory = (
  payload: Partial<ApplicationCommissionRequest> = {}
): ApplicationCommissionRequest => {
  return {
    currency_id_from: payload.currency_id_from ?? 0,
    currency_id_to: payload.currency_id_to ?? 0,
    currency_type_from: payload.currency_type_from ?? AdminCurrencyType.fiat,
    currency_type_to: payload.currency_type_to ?? AdminCurrencyType.fiat,
    amount: payload.amount ?? 0,
  };
};

export const ApplicationCommissionResponseFactory = (
  payload: Partial<ApplicationCommissionResponse> = {}
): ApplicationCommissionResponse => {
  return {
    commission: payload.commission ?? 0,
    transaction_volume: payload.transaction_volume ?? 0,
    execute_price: payload.execute_price ?? 0,
  };
};

export const ApplicationHistoryResponseFactory = (
  payload: Partial<ApplicationHistoryResponse> = {}
): ApplicationHistoryResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    currency_type_from: payload.currency_type_from ?? AdminCurrencyType.fiat,
    currency_type_to: payload.currency_type_to ?? AdminCurrencyType.fiat,
    currency_id_from: payload.currency_id_from ?? 0,
    currency_id_to: payload.currency_id_to ?? 0,
    user_requisite_id_from: payload.user_requisite_id_from ?? 0,
    user_requisite_type_from: payload.user_requisite_type_from ?? "",
    user_requisite_id_to: payload.user_requisite_id_to ?? 0,
    user_requisite_type_to: payload.user_requisite_type_to ?? "",
    amount: Number(payload.amount ?? 0),
    execution_price: Number(payload.execution_price ?? 0),
    transaction_volume: Number(payload.transaction_volume ?? 0),
    commissions: Number(payload.commissions ?? 0),
    status: payload.status ?? ApplicationStatus.pending,
    stage: payload.stage ?? "",
    admin_id: payload.admin_id ?? 0,
    deleted_at: payload.deleted_at ?? "",
    created_at: moment(payload.created_at ?? "").format("YYYY-MM-DD HH:mm:ss"),
    updated_at: payload.updated_at ?? "",
    currency_from: AdminCurrencyShowResponseFactory(
      payload.currency_from ?? {}
    ),
    currency_to: AdminCurrencyShowResponseFactory(payload.currency_to ?? {}),
  };
};
