<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <input
      :class="b('field', { error: isErrorShow })"
      type="text"
      :value="value"
      @input="changeValue"
    />

    <p :class="b('label')">
      {{ label }}
    </p>
  </div>
</template>
