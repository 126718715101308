import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  DefaultToolbar,
  DefaultContent,
  DefaultNavbar,
  DefaultModal,
} from "@/components/layouts/default";
import { getCookie, setCookie } from "@/shared/utils/cookie-helpers";
import { CookieName } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    DefaultToolbar,
    DefaultContent,
    DefaultNavbar,
    DefaultModal,
  },
  data() {
    return {
      isModal: false,
      userRepo: this.$projectServices.userRepo,
      walletRepo: this.$projectServices.walletRepo,
    };
  },
  async created(): Promise<void> {
    await this.walletRepo.wallet(this.userRepo.userInfo.id);

    if (this.userRepo.isVerified && !getCookie(CookieName.isVerification)) {
      this.isModal = true;

      setCookie(CookieName.isVerification, "true");
    }
  },
});
