import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { AdminApplicationListResponse } from "@/shared/repository/modules/admin/application/repo";
import { ApplicationStatus } from "@/shared/constants/enums";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";
import { SvgAttribute } from "@/shared/constants/constants";

enum OptionType {
  work = "work",
  edit = "edit",
  reject = "reject",
}

interface Option {
  id: number;
  label: string;
  isDisabled: boolean;
  type: OptionType;
}

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    row: {
      type: <PropType<AdminApplicationListResponse>>Object,
      required: true,
    },
  },
  emits: {
    history: null,
    application: null,
    work: null,
    reject: null,
  },
  data() {
    return {
      isLoading: false,
      isVisible: false,
      userRepo: this.$projectServices.userRepo,
      adminApplicationRepository:
        this.$projectServices.adminApplicationRepository,
    };
  },
  computed: {
    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedStatus(): string {
      switch (this.row.status) {
        case ApplicationStatus.pending:
          return "Создано";
        case ApplicationStatus.waiting:
          return "Ожидание подтверждения";
        case ApplicationStatus.reject:
          return "Отклонено";
        case ApplicationStatus.inWork:
          return "В работе";
        case ApplicationStatus.completed:
          return "Выполнено";
        default:
          return "";
      }
    },

    displayedAmountFrom(): string {
      return formatFiatCurrency(this.row.amount);
    },

    displayedAmountTo(): string {
      return formatFiatCurrency(
        (this.row.amount - this.row.commissions) * this.row.execution_price
      );
    },

    displayedExecutionPrice(): string {
      return formatFiatCurrency(this.row.execution_price);
    },

    displayedTransactionVolume(): string {
      return formatFiatCurrency(this.row.transaction_volume);
    },

    displayedCommission(): string {
      return formatFiatCurrency(this.row.commissions);
    },

    displayedOptions(): Option[] {
      const { status } = this.row;

      return [
        {
          id: 0,
          label: "Взять в работу",
          type: OptionType.work,
          isDisabled: this.isLoading || this.isAdminShow,
        },
        {
          id: 1,
          label: "Редактировать",
          type: OptionType.edit,
          isDisabled:
            this.isLoading ||
            status === ApplicationStatus.pending ||
            this.isAdminShow,
        },
        {
          id: 2,
          label: "Отклонить",
          type: OptionType.reject,
          isDisabled: this.isLoading || this.isAdminShow,
        },
      ];
    },

    isDisabled(): boolean {
      return this.isLoading;
    },

    isStageGroupShow(): boolean {
      return this.row.status === ApplicationStatus.pending;
    },

    isAdminShow(): boolean {
      if (this.row.admin.id) {
        return this.userRepo.userInfo.id !== this.row.admin.id;
      }

      return false;
    },
  },
  methods: {
    handleToggle(): void {
      this.isVisible = !this.isVisible;
    },

    handleHide(): void {
      this.isVisible = false;
    },

    handleInWork(): void {
      this.handleHide();

      this.$emit("work", true);
      this.$emit("reject", false);

      this.$emit("application", this.row);
    },

    handleOption({ type }: Option): void {
      switch (type) {
        case OptionType.work:
          this.$emit("work", true);
          this.$emit("reject", false);

          break;
        case OptionType.edit:
          this.$emit("work", false);
          this.$emit("reject", false);

          break;
        case OptionType.reject:
          this.$emit("work", false);
          this.$emit("reject", true);

          break;
        default:
          break;
      }

      this.$emit("application", this.row);

      this.handleHide();
    },

    async handleRemove(): Promise<void> {
      try {
        this.isLoading = true;

        await this.adminApplicationRepository.delete(this.row.id);

        this.$emit("history", await this.adminApplicationRepository.list());
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
