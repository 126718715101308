import { ApplicationStatus } from "../constants/enums";
import { HistoryFilterRequest } from "../constants/interfaces";

export const HistoryFilterRequestFactory = (
  payload: Partial<HistoryFilterRequest> = {}
): HistoryFilterRequest => {
  return {
    date_from: payload.date_from ?? "",
    date_to: payload.date_to ?? "",
    amount_from: payload.amount_from ?? 0,
    amount_to: payload.amount_to ?? 0,
    status: payload.status ?? ApplicationStatus.default,
    page: payload.page ?? 1,
    per_page: payload.per_page ?? 10,
    currency_id: payload.currency_id ?? 0,
    type: payload.type ?? "",
  };
};
