import { defineComponent } from "vue";

import { PAGE_NAME, SVG } from "./attributes";

import {
  AdminApplicationTable,
  AdminApplicationEdit,
  AdminApplicationEditApplication,
  AdminApplicationHeader,
} from "@/components/pages/admin-application";
import { SharedAdminHeader, SharedButtonIcon } from "@/components/shared";

import { PAGE_SIZE, SvgAttribute } from "@/shared/constants/constants";
import { SearchFactory } from "@/shared/factory/search";
import { SortFactory } from "@/shared/factory/sort";
import { AdminDepositWithdrawalListResponse } from "@/shared/repository/modules/admin/deposit-withdrawal/repo";
import { AdminDepositWithdrawalListResponseFactory } from "@/shared/repository/modules/admin/deposit-withdrawal/factory";
import { HistoryTab } from "@/shared/constants/enums";
import { AdminApplicationListResponse } from "@/shared/repository/modules/admin/application/repo";
import { AdminApplicationListResponseFactory } from "@/shared/repository/modules/admin/application/factory";

export default defineComponent({
  name: PAGE_NAME,
  components: {
    AdminApplicationTable,
    AdminApplicationEdit,
    AdminApplicationEditApplication,
    AdminApplicationHeader,
    SharedAdminHeader,
    SharedButtonIcon,
  },
  data() {
    return {
      isWork: false,
      isReject: false,
      isLoading: false,
      tab: HistoryTab.depositWithdrawal,
      search: SearchFactory(),
      sort: SortFactory(),
      depositWithdrawal: AdminDepositWithdrawalListResponseFactory(),
      application: AdminApplicationListResponseFactory(),
      history: <
        AdminDepositWithdrawalListResponse[] | AdminApplicationListResponse[]
      >[],
      adminDepositWithdrawalRepository:
        this.$projectServices.adminDepositWithdrawalRepository,
      adminApplicationRepository:
        this.$projectServices.adminApplicationRepository,
    };
  },
  async created(): Promise<void> {
    try {
      await this.handleFetch();
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    isMoreShow(): boolean {
      return (
        this.history.length > this.sort.page * PAGE_SIZE && !this.search.value
      );
    },
  },
  methods: {
    async handleFetch(): Promise<void> {
      try {
        this.isLoading = true;

        this.history = [];

        switch (this.tab) {
          case HistoryTab.depositWithdrawal:
            this.history = await this.adminDepositWithdrawalRepository.list();

            break;
          case HistoryTab.application:
            this.history = await this.adminApplicationRepository.list();

            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async handleSearch(): Promise<void> {
      try {
        this.isLoading = true;

        this.search.value = "";
        this.sort.page = 1;

        const { email, id } = this.search;

        switch (this.tab) {
          case HistoryTab.depositWithdrawal:
            this.history = await this.adminDepositWithdrawalRepository.list();

            if (email && id) {
              this.history = this.history.filter(
                (item) => item.user.email === email && item.user.id === id
              );
            } else if (email) {
              this.history = this.history.filter(
                (item) => item.user.email === email
              );
            } else if (id) {
              this.history = this.history.filter((item) => item.user.id === id);
            }

            break;
          case HistoryTab.application:
            this.history = await this.adminApplicationRepository.list();

            if (email && id) {
              this.history = this.history.filter(
                (item) => item.user.email === email && item.user.id === id
              );
            } else if (email) {
              this.history = this.history.filter(
                (item) => item.user.email === email
              );
            } else if (id) {
              this.history = this.history.filter((item) => item.user.id === id);
            }

            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleMore(): void {
      this.sort.page++;
    },
  },
});
