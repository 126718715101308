import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIInput = _resolveComponent("UIInput")!
  const _component_SharedDate = _resolveComponent("SharedDate")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedWindow = _resolveComponent("SharedWindow")!

  return (_openBlock(), _createBlock(_component_SharedWindow, {
    class: _normalizeClass(_ctx.b()),
    title: "Фильтр",
    onClose: _ctx.handleClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('wrapper'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('form'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('group'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('label'))
            }, _toDisplayString(_ctx.$t("Валюта")), 3),
            _createVNode(_component_SharedSelect, {
              view: "light",
              placeholder: _ctx.$t('Выберите валюту'),
              options: _ctx.displayedCurrencyOptions,
              value: _ctx.displayedFilter.currency_id,
              icon: _ctx.displayedCurrencyIcon,
              "onUpdate:value": _ctx.handleCurrency
            }, null, 8, ["placeholder", "options", "value", "icon", "onUpdate:value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('block'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('group'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label'))
              }, _toDisplayString(_ctx.$t("Объем от")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-number": true,
                "model-value": _ctx.displayedFilter.amount_from,
                "onUpdate:modelValue": _ctx.handleAmountFrom
              }, null, 8, ["model-value", "onUpdate:modelValue"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('group'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label'))
              }, _toDisplayString(_ctx.$t("до")), 3),
              _createVNode(_component_UIInput, {
                view: "light",
                "is-number": true,
                "model-value": _ctx.displayedFilter.amount_to,
                "onUpdate:modelValue": _ctx.handleAmountTo
              }, null, 8, ["model-value", "onUpdate:modelValue"])
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('block'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('group'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label'))
              }, _toDisplayString(_ctx.$t("Дата/Период от")), 3),
              _createVNode(_component_SharedDate, {
                format: "yyyy-MM-dd",
                date: _ctx.displayedFilter.date_from,
                "onUpdate:date": _ctx.handleDateFrom
              }, null, 8, ["date", "onUpdate:date"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('group'))
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.b('label'))
              }, _toDisplayString(_ctx.$t("до")), 3),
              _createVNode(_component_SharedDate, {
                format: "yyyy-MM-dd",
                date: _ctx.displayedFilter.date_to,
                "onUpdate:date": _ctx.handleDateTo
              }, null, 8, ["date", "onUpdate:date"])
            ], 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('group'))
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.b('label'))
            }, _toDisplayString(_ctx.$t("Статус")), 3),
            _createVNode(_component_SharedSelect, {
              view: "light",
              placeholder: _ctx.$t('Выберите статус'),
              options: _ctx.displayedStatusOptions,
              value: _ctx.displayedFilter.status,
              "onUpdate:value": _ctx.handleStatus
            }, null, 8, ["placeholder", "options", "value", "onUpdate:value"])
          ], 2),
          (_ctx.isDepositWithdrawal)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.b('group'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('label'))
                }, _toDisplayString(_ctx.$t("Тип")), 3),
                _createVNode(_component_SharedSelect, {
                  view: "light",
                  placeholder: _ctx.$t('Выберите тип'),
                  options: _ctx.displayedTypeOptions,
                  value: _ctx.displayedFilter.type,
                  "onUpdate:value": _ctx.handleType
                }, null, 8, ["placeholder", "options", "value", "onUpdate:value"])
              ], 2))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode(_component_UIButton, {
          label: "Применить фильтр",
          view: "main",
          disabled: _ctx.isDisabled,
          onClick: _ctx.handleApply
        }, null, 8, ["disabled", "onClick"])
      ], 2)
    ]),
    _: 1
  }, 8, ["class", "onClose"]))
}