import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedSearch = _resolveComponent("SharedSearch")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('tabs'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedTabs, (tab) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(_ctx.b('tab', { active: tab.acitve })),
          key: tab.id,
          disabled: tab.isDisabled,
          onClick: ($event: any) => (_ctx.handleTab(tab))
        }, _toDisplayString(_ctx.$t(tab.label)), 11, _hoisted_1))
      }), 128))
    ], 2),
    _createVNode(_component_SharedSearch, {
      search: _ctx.search,
      "onUpdate:search": _ctx.handleSearch
    }, null, 8, ["search", "onUpdate:search"])
  ], 2))
}