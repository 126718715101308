import { AxiosInstance } from "axios";

import { AdminDepositWithdrawalUrlGenerator } from "./url-generator";
import {
  AdminDepositWithdrawalListResponse,
  AdminDepositWithdrawalShowResponse,
  AdminDepositWithdrawalUpdateRequest,
} from "./repo";
import { ResponseData } from "@/shared/constants/interfaces";
import {
  AdminDepositWithdrawalListResponseFactory,
  AdminDepositWithdrawalShowResponseFactory,
} from "./factory";
import { AdminDepositWithdrawalStatus } from "./enums";

export interface AdminDepositWithdrawalProjectRepository {
  update(
    status: AdminDepositWithdrawalStatus,
    payload: AdminDepositWithdrawalUpdateRequest
  ): Promise<void>;
  delete(id: number): Promise<void>;
  show(id: number): Promise<AdminDepositWithdrawalShowResponse>;
  list(): Promise<AdminDepositWithdrawalListResponse[]>;
}

export class AdminDepositWithdrawalHttpRepo
  implements AdminDepositWithdrawalProjectRepository
{
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: AdminDepositWithdrawalUrlGenerator
  ) {}

  async update(
    status: AdminDepositWithdrawalStatus,
    payload: AdminDepositWithdrawalUpdateRequest
  ): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.update(status), payload);
  }

  async delete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.delete(id));
  }

  async show(id: number): Promise<AdminDepositWithdrawalShowResponse> {
    const { data } = await this.axios.get<
      ResponseData<AdminDepositWithdrawalShowResponse>
    >(this.urlGenerator.show(id));

    return AdminDepositWithdrawalShowResponseFactory(data.data);
  }

  async list(): Promise<AdminDepositWithdrawalListResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<AdminDepositWithdrawalListResponse[]>
    >(this.urlGenerator.list());

    return data.data.map((item) =>
      AdminDepositWithdrawalListResponseFactory(item)
    );
  }
}
