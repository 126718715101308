<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <WalletCard :class="b()" title="Заявки" tab="application">
    <div :class="b('table')">
      <div :class="b('columns')">
        <div
          :class="b('column')"
          v-for="column in displayedColumns"
          :key="column.id"
        >
          <p :class="b('column-label')">
            {{ $t(column.label) }}
          </p>
        </div>
      </div>

      <div :class="b('rows')">
        <WalletApplicationsRow
          :class="b('row')"
          v-for="(row, rowIndex) in displayedHistory"
          :key="rowIndex"
          :row="row"
        />
      </div>
    </div>
  </WalletCard>
</template>
