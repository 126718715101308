<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" title="Подтверждение заявки" @close="handleClose">
    <div :class="b('content')">
      <div :class="b('list')">
        <div :class="b('item')">
          <h4 :class="b('item-title')">
            {{ $t("Со счета") }}
          </h4>

          <p :class="b('item-label')">
            {{ displayedCurrencyFrom.currency.name.toUpperCase() }}
          </p>
        </div>

        <div :class="b('item', { to: true })">
          <div :class="b('item-group')">
            <h4 :class="b('item-title')">
              {{ $t("На счет") }}
            </h4>

            <p :class="b('item-label')">
              {{
                displayedCurrencyTo.currency?.name.toUpperCase() ||
                displayedCurrencyTo.name
              }}
            </p>
          </div>

          <!-- <div :class="b('item-copy')">
            12erGfIdfdfEBDdfgDFG7pokmFwe3Z

            <button :class="b('item-copy-action')" @click="handleCopy">
              <span :class="b('item-icon')" v-html="displayedSvg.copy" />
            </button>
          </div> -->
        </div>

        <div :class="b('item')">
          <h4 :class="b('item-title')">
            {{ $t("Текущий курс") }}
          </h4>

          <p :class="b('item-label')">
            {{ displayedCourse }}
            {{ displayedCurrencyTo?.name.toUpperCase() }}
          </p>
        </div>

        <div :class="b('item', { operation: true })">
          <h4 :class="b('item-title')">
            {{ $t("Операция") }}
          </h4>

          <div :class="b('item-operation')">
            <span :class="b('item-icon')" v-html="displayedSvg.chevron" />

            <div :class="b('item-operations')">
              <p :class="b('item-label')">
                {{ displayedAmountFrom }}
                {{ displayedCurrencyFrom.currency.name.toUpperCase() }}
              </p>

              <p :class="b('item-label')">
                ~ {{ displayedAmountTo }}
                {{ displayedCurrencyTo.name.toUpperCase() }}
              </p>
            </div>
          </div>
        </div>

        <div :class="b('item')">
          <h4 :class="b('item-title')">
            {{ $t("Комиссия") }}
          </h4>

          <p :class="b('item-label')">{{ displayedCommissions }}</p>
        </div>
      </div>

      <UIButton label="Создать заявку" view="main" @click="handleCreate" />
    </div>
  </SharedWindow>
</template>
