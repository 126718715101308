import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedWindow,
  SharedSelect,
  SharedInputCurrency,
  SharedEstimate,
} from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import { AdminDepositWithdrawalListResponse } from "@/shared/repository/modules/admin/deposit-withdrawal/repo";
import { AdminDepositWithdrawalStatus } from "@/shared/repository/modules/admin/deposit-withdrawal/enums";
import {
  AdminApplicationListResponseFactory,
  AdminApplicationShowResponseFactory,
  AdminApplicationUpdateRequestFactory,
} from "@/shared/repository/modules/admin/application/factory";
import { ApplicationStatus } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedWindow,
    SharedSelect,
    SharedInputCurrency,
    SharedEstimate,
    UIInput,
    UIButton,
  },
  props: {
    history: {
      type: <PropType<AdminDepositWithdrawalListResponse[]>>Array,
      default: () => [],
    },
    application: {
      type: <PropType<AdminDepositWithdrawalListResponse>>Object,
      required: true,
    },
    isWork: {
      type: Boolean,
      default: false,
    },
    isReject: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:history": null,
    "update:application": null,
  },
  data() {
    return {
      isLoading: false,
      adminApplicationShow: AdminApplicationShowResponseFactory(),
      adminApplicationRepository:
        this.$projectServices.adminApplicationRepository,
    };
  },
  async created() {
    try {
      this.isLoading = true;

      this.adminApplicationShow = await this.adminApplicationRepository.show(
        this.application.id
      );

      if (this.isWork) {
        this.adminApplicationShow.status = ApplicationStatus.inWork;
      } else if (this.isReject) {
        this.adminApplicationShow.status = ApplicationStatus.reject;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedStatusOptions(): SharedSelectOption[] {
      return [
        SelectOptionFactory(
          {
            id: 0,
            label: "Создано",
            value: AdminDepositWithdrawalStatus.pending,
          },
          this.adminApplicationShow.status
        ),
        SelectOptionFactory(
          {
            id: 1,
            label: "Ожидание подтверждения",
            value: AdminDepositWithdrawalStatus.waiting,
          },
          this.adminApplicationShow.status
        ),
        SelectOptionFactory(
          {
            id: 2,
            label: "Отклонено",
            value: AdminDepositWithdrawalStatus.reject,
          },
          this.adminApplicationShow.status
        ),
        SelectOptionFactory(
          {
            id: 3,
            label: "В работе",
            value: AdminDepositWithdrawalStatus.in_work,
          },
          this.adminApplicationShow.status
        ),
        SelectOptionFactory(
          {
            id: 3,
            label: "Выполнено",
            value: AdminDepositWithdrawalStatus.completed,
          },
          this.adminApplicationShow.status
        ),
      ];
    },

    isDisabled(): boolean {
      const { id, status, amount } = this.adminApplicationShow;

      return !id || !status || !amount || this.isLoading;
    },
  },
  methods: {
    handleAmount(amount: number): void {
      this.adminApplicationShow.amount = amount;

      const { transaction_volume, execution_price } = this.adminApplicationShow;

      if (transaction_volume && execution_price) {
        this.adminApplicationShow.transaction_volume = amount / execution_price;
        this.adminApplicationShow.execution_price = amount / transaction_volume;
      }
    },

    handleTransactionVolume(transaction_volume: number): void {
      this.adminApplicationShow.transaction_volume = transaction_volume;

      const { execution_price, amount } = this.adminApplicationShow;

      this.adminApplicationShow.amount = execution_price * transaction_volume;

      if (transaction_volume) {
        this.adminApplicationShow.execution_price = amount / transaction_volume;
      }
    },

    handleExecutionPrice(execution_price: number): void {
      this.adminApplicationShow.execution_price = execution_price;

      const { transaction_volume, amount } = this.adminApplicationShow;

      this.adminApplicationShow.amount = transaction_volume * execution_price;

      if (execution_price) {
        this.adminApplicationShow.transaction_volume = amount / execution_price;
      }
    },

    handleClose(): void {
      this.$emit("update:application", AdminApplicationListResponseFactory());
    },

    async handleSave(): Promise<void> {
      try {
        this.isLoading = true;

        await this.adminApplicationRepository.update(
          this.application.id,
          this.adminApplicationShow.status,
          AdminApplicationUpdateRequestFactory(this.adminApplicationShow)
        );

        this.$emit(
          "update:history",
          await this.adminApplicationRepository.list()
        );

        this.handleClose();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
