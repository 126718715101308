import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { WalletCard } from "../card";
import { WalletApplicationsRow } from "./row";

import { ApplicationHistoryResponse } from "@/shared/repository/modules/application/repo";

export interface WalletApplicationsColumn {
  id: number;
  label: string;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    WalletCard,
    WalletApplicationsRow,
  },
  props: {
    history: {
      type: <PropType<ApplicationHistoryResponse[]>>Array,
      default: () => [],
    },
  },
  computed: {
    displayedColumns(): WalletApplicationsColumn[] {
      return [
        {
          id: 0,
          label: "Дата и время",
        },
        {
          id: 0,
          label: "Операция",
        },
      ];
    },

    displayedHistory(): ApplicationHistoryResponse[] {
      return this.history
        .slice()
        .sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        .slice(0, 5);
    },
  },
});
