import { SvgAttribute } from "@/shared/constants/constants";

export const COMPONENT_NAME = "history-application-details";

export const SVG: SvgAttribute = {
  chevron: `<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33333 0.444445C8.64016 0.13762 9.13762 0.13762 9.44444 0.444445C9.75127 0.75127 9.75127 1.24873 9.44444 1.55556L5.55556 5.44445C5.24873 5.75127 4.75127 5.75127 4.44444 5.44445C4.13762 5.13762 4.13762 4.64016 4.44444 4.33333L8.33333 0.444445Z" fill="#9E9FA8"/>
    <path d="M5.55556 4.33333C5.86238 4.64016 5.86238 5.13762 5.55556 5.44445C5.24873 5.75127 4.75127 5.75127 4.44444 5.44445L0.555555 1.55556C0.248731 1.24873 0.248731 0.751269 0.555556 0.444444C0.862381 0.137619 1.35984 0.13762 1.66667 0.444445L5.55556 4.33333Z" fill="#9E9FA8"/>
  </svg>`,
};
