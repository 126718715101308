import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WalletMain = _resolveComponent("WalletMain")!
  const _component_WalletHistory = _resolveComponent("WalletHistory")!
  const _component_WalletApplications = _resolveComponent("WalletApplications")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h2", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Кошелек")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      _createVNode(_component_WalletMain),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('group'))
      }, [
        _createVNode(_component_WalletHistory, { history: _ctx.depositWithdrawalHistory }, null, 8, ["history"]),
        _createVNode(_component_WalletApplications, { history: _ctx.applicationHistory }, null, 8, ["history"])
      ], 2)
    ], 2),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isModalShow)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedModal), {
              key: 0,
              class: _normalizeClass(_ctx.b('modal'))
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}