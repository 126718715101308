<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h2 :class="b('title')">
      {{ $t("Кошелек") }}
    </h2>

    <div :class="b('wrapper')">
      <WalletMain />

      <div :class="b('group')">
        <WalletHistory :history="depositWithdrawalHistory" />

        <WalletApplications :history="applicationHistory" />
      </div>
    </div>

    <transition name="opacity">
      <component v-if="isModalShow" :class="b('modal')" :is="displayedModal" />
    </transition>
  </div>
</template>
