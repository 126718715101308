import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SharedSearch } from "@/components/shared";
import { HistoryTab } from "@/shared/constants/enums";
import { AdminApplicationListResponse } from "@/shared/repository/modules/admin/application/repo";
import { AdminDepositWithdrawalListResponse } from "@/shared/repository/modules/admin/deposit-withdrawal/repo";

export interface AdminApplicationHeaderTab {
  id: number;
  label: string;
  value: HistoryTab;
  acitve: boolean;
  isDisabled: boolean;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedSearch,
  },
  props: {
    tab: {
      type: <PropType<HistoryTab>>String,
      default: HistoryTab.depositWithdrawal,
    },
    search: {
      type: String,
      default: "",
    },
    history: {
      type: <
        PropType<
          AdminDepositWithdrawalListResponse[] | AdminApplicationListResponse[]
        >
      >Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  emits: {
    "update:tab": null,
    "update:search": null,
    "update:history": null,
    "update:page": null,
  },
  data() {
    return {
      isLoading: false,
      adminDepositWithdrawalRepository:
        this.$projectServices.adminDepositWithdrawalRepository,
      adminApplicationRepository:
        this.$projectServices.adminApplicationRepository,
    };
  },
  computed: {
    displayedTabs(): AdminApplicationHeaderTab[] {
      return [
        {
          id: 0,
          label: "Пополнение/Вывод",
          value: HistoryTab.depositWithdrawal,
          acitve: this.tab === HistoryTab.depositWithdrawal,
          isDisabled: this.isLoading,
        },
        {
          id: 1,
          label: "Заявки",
          value: HistoryTab.application,
          acitve: this.tab === HistoryTab.application,
          isDisabled: this.isLoading,
        },
      ];
    },
  },
  methods: {
    async handleTab({ value }: AdminApplicationHeaderTab): Promise<void> {
      if (this.tab !== value) {
        try {
          this.isLoading = true;

          this.$emit("update:page", 1);
          this.$emit("update:history", []);

          let history:
            | AdminDepositWithdrawalListResponse[]
            | AdminApplicationListResponse[] = [];

          switch (value) {
            case HistoryTab.depositWithdrawal:
              history = await this.adminDepositWithdrawalRepository.list();

              break;
            case HistoryTab.application:
              history = await this.adminApplicationRepository.list();

              break;
            default:
              break;
          }

          this.$emit("update:history", history);
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }

        this.$emit("update:tab", value);
      }
    },

    handleSearch(search: string): void {
      this.$emit("update:page", 1);

      this.$emit("update:search", search);
    },
  },
});
