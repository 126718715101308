import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('date-time'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.row.created_at), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('type'))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('icon')),
        innerHTML: _ctx.displayedSvg[_ctx.row.app_type]
      }, null, 10, _hoisted_1),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label', { type: true }))
      }, _toDisplayString(_ctx.row.app_type === "deposit" ? "Ввод" : "Вывод"), 3),
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label', { amount: true }))
      }, _toDisplayString(_ctx.displayedAmount), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('amount'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.displayedAmount), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('currency'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('currency-group'))
      }, [
        (_ctx.row.currency.country && _ctx.row.currency_type === 'fiat')
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([_ctx.b('icon'), 'fi', `fi-${_ctx.row.currency.country}`])
            }, null, 2))
          : (_ctx.row.currency_type === 'crypto' && _ctx.row.currency.name)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: _normalizeClass(_ctx.b('icon', { crypto: true })),
                src: 
            require(`@/assets/images/crypto/${_ctx.row.currency.name.toLowerCase()}.svg`)
          ,
                alt: ""
              }, null, 10, _hoisted_2))
            : _createCommentVNode("", true),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label', { currency: true }))
        }, _toDisplayString(_ctx.row.currency.name), 3)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('icon', { status: true })),
        innerHTML: _ctx.displayedSvg[_ctx.row.status]
      }, null, 10, _hoisted_3)
    ], 2)
  ], 2))
}