import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { AdminApplicationTableRow } from "./row";
import { AdminApplicationTableRowApplication } from "./row-application";

import { PAGE_SIZE, SvgAttribute } from "@/shared/constants/constants";
import { AdminDepositWithdrawalListResponse } from "@/shared/repository/modules/admin/deposit-withdrawal/repo";
import { Search, Sort } from "@/shared/constants/interfaces";
import { AdminApplicationListResponse } from "@/shared/repository/modules/admin/application/repo";
import { HistoryTab } from "@/shared/constants/enums";

type AdminApplicationTablComponent =
  | typeof AdminApplicationTableRow
  | typeof AdminApplicationTableRowApplication;

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminApplicationTableRow,
    AdminApplicationTableRowApplication,
  },
  props: {
    history: {
      type: <
        PropType<
          AdminDepositWithdrawalListResponse[] | AdminApplicationListResponse[]
        >
      >Array,
      default: () => [],
    },
    depositWithdrawal: {
      type: <PropType<AdminDepositWithdrawalListResponse>>Object,
      required: true,
    },
    search: {
      type: <PropType<Search>>Object,
      required: true,
    },
    sort: {
      type: <PropType<Sort>>Object,
      required: true,
    },
    tab: {
      type: <PropType<HistoryTab>>String,
      default: HistoryTab.depositWithdrawal,
    },
    isWork: {
      type: Boolean,
      default: false,
    },
    isReject: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:history": null,
    "update:depositWithdrawal": null,
    "update:application": null,
    "update:isWork": null,
    "update:isReject": null,
  },
  computed: {
    displayedIcons(): SvgAttribute {
      return SVG;
    },

    displayedColumns(): string[] {
      switch (this.tab) {
        case HistoryTab.depositWithdrawal:
          return [
            "<span>Дата</span>",
            "<span>ID <br /> польз.</span>",
            "<span>почта</span>",
            "<span>тип</span>",
            "<span>сумма</span>",
            "<span>Цена <br /> исполнения</span>",
            "<span>Объем <br /> сделки</span>",
            "<span>Комис.</span>",
            "<span>№</span>",
            "<span>Статус</span>",
            "<span>Этап</span>",
          ];
        default:
          return [
            "<span>Дата</span>",
            "<span>ID <br /> польз.</span>",
            "<span>почта</span>",
            "<span>Операция</span>",
            "<span>Цена <br /> исполнения</span>",
            "<span>Объем <br /> сделки</span>",
            "<span>Комис.</span>",
            "<span>№</span>",
            "<span>Статус</span>",
            "<span>Этап</span>",
          ];
      }
    },

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    displayedHistory():
      | AdminDepositWithdrawalListResponse[]
      | AdminApplicationListResponse[] {
      switch (this.tab) {
        case HistoryTab.depositWithdrawal:
          return this.search.value
            ? (this.history as AdminDepositWithdrawalListResponse[]).filter(
                (item) =>
                  `${item.id} ${item.amount} ${item.created_at} ${item.user.id} ${item.user.email}`
                    .toLowerCase()
                    .includes(this.search.value.toLowerCase())
              )
            : (this.history.slice(
                0,
                PAGE_SIZE * this.sort.page
              ) as AdminDepositWithdrawalListResponse[]);
        case HistoryTab.application:
          return this.search.value
            ? (this.history as AdminApplicationListResponse[]).filter((item) =>
                `${item.id} ${item.amount} ${item.created_at} ${item.user.id} ${item.user.email}`
                  .toLowerCase()
                  .includes(this.search.value.toLowerCase())
              )
            : (this.history.slice(
                0,
                PAGE_SIZE * this.sort.page
              ) as AdminApplicationListResponse[]);
        default:
          return [];
      }
    },

    displayedRow(): AdminApplicationTablComponent {
      switch (this.tab) {
        case HistoryTab.depositWithdrawal:
          return AdminApplicationTableRow;
        case HistoryTab.application:
          return AdminApplicationTableRowApplication;
        default:
          return AdminApplicationTableRow;
      }
    },

    isApplication(): boolean {
      return this.tab === HistoryTab.application;
    },
  },
  methods: {
    handleHistory(history: AdminDepositWithdrawalListResponse[]): void {
      this.$emit("update:history", history);
    },

    handleDepositWithdrawal(
      depositWithdrawal: AdminDepositWithdrawalListResponse
    ): void {
      this.$emit("update:depositWithdrawal", depositWithdrawal);
    },

    handleApplication(application: AdminApplicationListResponse): void {
      this.$emit("update:application", application);
    },

    handleWork(isWork: boolean): void {
      this.$emit("update:isWork", isWork);
    },

    handleReject(isReject: boolean): void {
      this.$emit("update:isReject", isReject);
    },
  },
});
