import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { SettingsRequisitesVerification } from "../verification";
import { SettingsRequisitesComponent } from "../model";

import { SharedCard, SharedBack, SharedValidate } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import {
  RequisitesCryptoFieldsFactory,
  RequisitesCryptoRequestFactory,
  RequisitesCryptoUpdateRequestFactory,
} from "@/shared/repository/modules/requisites/factory";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SettingsRequisitesVerification,
    SharedCard,
    SharedBack,
    SharedValidate,
    UIInput,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<SettingsRequisitesComponent>>String,
      required: true,
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      isVerification: false,
      userRepo: this.$projectServices.userRepo,
      requisitesRepo: this.$projectServices.requisitesRepo,
      requisitesRepository: this.$projectServices.requisitesRepository,
      requisitesCryptoRequest: RequisitesCryptoRequestFactory(),
      requisitesCryptoFields: RequisitesCryptoFieldsFactory(),
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      if (this.requisitesRepo.cryptoId) {
        this.requisitesCryptoRequest =
          await this.requisitesRepository.cryptoShow(
            this.requisitesRepo.cryptoId,
            this.userRepo.userInfo.id
          );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    isDisabled(): boolean {
      return Object.values(this.requisitesCryptoFields).some((field) => !field);
    },

    isContinueDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", SettingsRequisitesComponent.main);

      this.requisitesRepo.UPDATE_CRYPTO_ID(null);
    },

    handleVerificationClose(): void {
      this.isVerification = false;

      this.$emit("update:component", SettingsRequisitesComponent.main);

      this.requisitesRepo.UPDATE_CRYPTO_ID(null);
    },

    async handleContinue(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.isDisabled) {
          this.isValidate = true;

          return;
        }

        if (this.requisitesRepo.cryptoId) {
          await this.requisitesRepository.cryptoUpdate(
            this.requisitesRepo.cryptoId,
            RequisitesCryptoUpdateRequestFactory(this.requisitesCryptoRequest)
          );

          this.handleBack();
        } else {
          await this.requisitesRepository.crypto(
            this.requisitesCryptoRequest,
            this.userRepo.userInfo.id
          );

          this.isVerification = true;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
