import { AxiosInstance } from "axios";

import { KybUrlGenerator } from "./url-generator";
import {
  KybBasicInfoRequest,
  KybBasicInfoResponse,
  KybCompany,
  KybParticipant,
  KybParticipantRequest,
  KybParticipantResponse,
  KybParticipantUploadFileRequest,
  KybParticipantUploadFileResponse,
  KybRegistrationInfoRequest,
  KybRegistrationInfoResponse,
  KybUploadFileRequest,
  KybUploadFileResponse,
} from "./repo";
import {
  KybBasicInfoResponseFactory,
  KybCompanyFactory,
  KybParticipantFactory,
  KybParticipantResponseFactory,
  KybParticipantUploadFileResponseFactory,
  KybRegistrationInfoResponseFactory,
  KybUploadFileResponseFactory,
} from "./factory";

import { ResponseData } from "@/shared/constants/interfaces";
import {
  KybDocumentsFileName,
  KybParticipantDocumentsFileName,
} from "@/shared/constants/enums";

export interface KybProjectRepository {
  company(userId: number): Promise<KybCompany>;
  companyById(id: number): Promise<KybCompany>;

  basicInfo(userId: number): Promise<KybBasicInfoResponse>;
  basicInfoById(id: number): Promise<KybBasicInfoResponse>;
  basicInfoUpdate(payload: KybBasicInfoRequest): Promise<KybBasicInfoResponse>;

  registrationInfo(userId: number): Promise<KybRegistrationInfoResponse>;
  registrationInfoById(id: number): Promise<KybRegistrationInfoResponse>;
  registrationInfoUpdate(
    payload: KybRegistrationInfoRequest
  ): Promise<KybRegistrationInfoResponse>;

  participants(userId: number): Promise<KybParticipant[]>;
  participantsById(id: number): Promise<KybParticipant[]>;
  participantById(id: number): Promise<KybParticipantResponse>;
  participant(payload: KybParticipantRequest): Promise<KybParticipantResponse>;
  participantDelete(id: number): Promise<void>;
  participantUpdate(
    id: number,
    payload: KybParticipantRequest
  ): Promise<KybParticipantResponse>;
  participantUploadFile(
    payload: KybParticipantUploadFileRequest
  ): Promise<KybParticipantUploadFileResponse>;
  participantDeleteFile(id: number, participantId: number): Promise<void>;
  participantFiles(
    participantId: number,
    userId: number
  ): Promise<KybParticipantUploadFileResponse[]>;
  participantFilesById(
    id: number,
    participantId: number
  ): Promise<KybParticipantUploadFileResponse[]>;

  userFiles(userId: number): Promise<KybUploadFileResponse[]>;
  userFilesById(id: number): Promise<KybUploadFileResponse[]>;
  uploadFile(payload: KybUploadFileRequest): Promise<KybUploadFileResponse>;
  deleteFile(id: number): Promise<void>;

  verification(): Promise<void>;
}

export class KybHttpRepo implements KybProjectRepository {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly urlGenerator: KybUrlGenerator
  ) {}

  async company(userId: number): Promise<KybCompany> {
    const [basicInfo, registrationInfo, participants, files] =
      await Promise.allSettled([
        this.basicInfo(userId),
        this.registrationInfo(userId),
        this.participants(userId),
        this.userFiles(userId),
      ]);

    return KybCompanyFactory({
      isBasic:
        basicInfo.status === "fulfilled" &&
        registrationInfo.status === "fulfilled",
      isParticipants:
        participants.status === "fulfilled" && participants.value.length
          ? {
              isSuccess: !participants.value.some(
                (participant) => participant.isExpected
              ),
              isWaiting: false,
              isExpected: participants.value.some(
                (participant) => participant.isExpected
              ),
            }
          : {
              isSuccess: false,
              isWaiting: true,
              isExpected: false,
            },
      isFiles:
        files.status === "fulfilled" &&
        files.value.length === Object.keys(KybDocumentsFileName).length,
    });
  }

  async companyById(id: number): Promise<KybCompany> {
    const [basicInfo, registrationInfo, participants, files] =
      await Promise.allSettled([
        this.basicInfoById(id),
        this.registrationInfoById(id),
        this.participantsById(id),
        this.userFilesById(id),
      ]);

    return KybCompanyFactory({
      isBasic:
        basicInfo.status === "fulfilled" &&
        registrationInfo.status === "fulfilled",
      isParticipants:
        participants.status === "fulfilled"
          ? {
              isSuccess: !participants.value.some(
                (participant) => participant.isExpected
              ),
              isWaiting: false,
              isExpected: participants.value.some(
                (participant) => participant.isExpected
              ),
            }
          : {
              isSuccess: false,
              isWaiting: true,
              isExpected: false,
            },
      isFiles:
        files.status === "fulfilled" &&
        files.value.length === Object.keys(KybDocumentsFileName).length,
    });
  }

  async basicInfo(userId: number): Promise<KybBasicInfoResponse> {
    const { data } = await this.axios.get<ResponseData<KybBasicInfoResponse>>(
      this.urlGenerator.basicInfo(),
      {
        params: {
          id: userId,
          user_id: userId,
        },
      }
    );

    return KybBasicInfoResponseFactory(data.data);
  }

  async basicInfoById(id: number): Promise<KybBasicInfoResponse> {
    const { data } = await this.axios.get<ResponseData<KybBasicInfoResponse>>(
      this.urlGenerator.basicInfo(),
      {
        params: {
          id,
          user_id: id,
        },
      }
    );

    return KybBasicInfoResponseFactory(data.data);
  }

  async basicInfoUpdate(
    payload: KybBasicInfoRequest
  ): Promise<KybBasicInfoResponse> {
    const { data } = await this.axios.post<ResponseData<KybBasicInfoResponse>>(
      this.urlGenerator.basicInfo(),
      payload
    );

    return KybBasicInfoResponseFactory(data.data);
  }

  async registrationInfo(userId: number): Promise<KybRegistrationInfoResponse> {
    const { data } = await this.axios.get<
      ResponseData<KybRegistrationInfoResponse>
    >(this.urlGenerator.registrationInfo(), {
      params: {
        id: userId,
        user_id: userId,
      },
    });

    return KybRegistrationInfoResponseFactory(data.data);
  }

  async registrationInfoById(id: number): Promise<KybRegistrationInfoResponse> {
    const { data } = await this.axios.get<
      ResponseData<KybRegistrationInfoResponse>
    >(this.urlGenerator.registrationInfo(), {
      params: {
        id,
        user_id: id,
      },
    });

    return KybRegistrationInfoResponseFactory(data.data);
  }

  async registrationInfoUpdate(
    payload: KybRegistrationInfoRequest
  ): Promise<KybRegistrationInfoResponse> {
    const { data } = await this.axios.post<
      ResponseData<KybRegistrationInfoResponse>
    >(this.urlGenerator.registrationInfo(), payload);

    return KybRegistrationInfoResponseFactory(data.data);
  }

  async participants(userId: number): Promise<KybParticipant[]> {
    const { data } = await this.axios.get<ResponseData<KybParticipant[]>>(
      this.urlGenerator.participants(),
      {
        params: {
          id: userId,
          user_id: userId,
        },
      }
    );

    const participants = data.data.map((item) => KybParticipantFactory(item));

    for await (const participant of participants) {
      await Promise.allSettled([
        this.participantById(participant.id!),
        this.participantFiles(participant.id!, userId),
      ]).then(([participantInfo, participantFiles]) => {
        if (
          participantInfo.status === "fulfilled" &&
          participantFiles.status === "fulfilled"
        ) {
          if (
            participantFiles.value.length ===
            Object.keys(KybParticipantDocumentsFileName).length
          ) {
            participant.isSuccess = true;
          } else {
            participant.isExpected = true;
          }
        } else {
          participant.isExpected = true;
        }
      });
    }

    return participants;
  }

  async participantsById(id: number): Promise<KybParticipant[]> {
    const { data } = await this.axios.get<ResponseData<KybParticipant[]>>(
      this.urlGenerator.participants(),
      {
        params: {
          id,
          user_id: id,
        },
      }
    );

    const participants = data.data.map((item) => KybParticipantFactory(item));

    for await (const participant of participants) {
      await Promise.allSettled([
        this.participantById(participant.id!),
        this.participantFilesById(id, participant.id!),
      ]).then(([participantInfo, participantFiles]) => {
        if (
          participantInfo.status === "fulfilled" &&
          participantFiles.status === "fulfilled"
        ) {
          if (
            participantFiles.value.length ===
            Object.keys(KybParticipantDocumentsFileName).length
          ) {
            participant.isSuccess = true;
          } else {
            participant.isExpected = true;
          }
        } else {
          participant.isExpected = true;
        }
      });
    }

    return participants;
  }

  async participantById(id: number): Promise<KybParticipantResponse> {
    const { data } = await this.axios.get<ResponseData<KybParticipantResponse>>(
      this.urlGenerator.participantById(id)
    );

    return KybParticipantResponseFactory(data.data);
  }

  async participant(
    payload: KybParticipantRequest
  ): Promise<KybParticipantResponse> {
    const { data } = await this.axios.post<
      ResponseData<KybParticipantResponse>
    >(this.urlGenerator.participant(), payload);

    return KybParticipantResponseFactory(data.data);
  }

  async participantDelete(id: number): Promise<void> {
    await this.axios.delete<void>(this.urlGenerator.participantDelete(id));
  }

  async participantUpdate(
    id: number,
    payload: KybParticipantRequest
  ): Promise<KybParticipantResponse> {
    const { data } = await this.axios.patch<
      ResponseData<KybParticipantResponse>
    >(this.urlGenerator.participantById(id), payload);

    return data.data as KybParticipantResponse;
  }

  async participantUploadFile(
    payload: KybParticipantUploadFileRequest
  ): Promise<KybParticipantUploadFileResponse> {
    const { data } = await this.axios.post<
      ResponseData<KybParticipantUploadFileResponse>
    >(this.urlGenerator.participantUploadFile(), payload);

    return KybParticipantUploadFileResponseFactory(data.data);
  }

  async participantDeleteFile(
    id: number,
    participantId: number
  ): Promise<void> {
    await this.axios.delete<void>(
      `${this.urlGenerator.participantDeleteFile()}${id}`,
      {
        params: {
          kyb_participant_id: participantId,
        },
      }
    );
  }

  async participantFiles(
    participantId: number,
    userId: number
  ): Promise<KybParticipantUploadFileResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<KybParticipantUploadFileResponse[]>
    >(this.urlGenerator.participantFiles(userId, participantId));

    return data.data.map((item) =>
      KybParticipantUploadFileResponseFactory(item)
    );
  }

  async participantFilesById(
    id: number,
    participantId: number
  ): Promise<KybParticipantUploadFileResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<KybParticipantUploadFileResponse[]>
    >(this.urlGenerator.participantFiles(id, participantId));

    return data.data.map((item) =>
      KybParticipantUploadFileResponseFactory(item)
    );
  }

  async userFiles(userId: number): Promise<KybUploadFileResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<KybUploadFileResponse[]>
    >(this.urlGenerator.userFiles(), {
      params: {
        id: userId,
        user_id: userId,
      },
    });

    return data.data
      .map((item) => KybUploadFileResponseFactory(item))
      .filter(
        (file) =>
          KybDocumentsFileName[file.name as keyof typeof KybDocumentsFileName]
      );
  }

  async userFilesById(id: number): Promise<KybUploadFileResponse[]> {
    const { data } = await this.axios.get<
      ResponseData<KybUploadFileResponse[]>
    >(this.urlGenerator.userFiles(), {
      params: {
        id,
        user_id: id,
      },
    });

    return data.data
      .map((item) => KybUploadFileResponseFactory(item))
      .filter(
        (file) =>
          KybDocumentsFileName[file.name as keyof typeof KybDocumentsFileName]
      );
  }

  async uploadFile(
    params: KybUploadFileRequest
  ): Promise<KybUploadFileResponse> {
    const { data } = await this.axios.post<ResponseData<KybUploadFileResponse>>(
      this.urlGenerator.uploadFile(),
      params
    );

    return KybUploadFileResponseFactory(data.data);
  }

  async deleteFile(id: number): Promise<void> {
    await this.axios.delete<void>(`${this.urlGenerator.deleteFile()}${id}`);
  }

  async verification(): Promise<void> {
    await this.axios.patch<void>(this.urlGenerator.verification());
  }
}
