import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { WalletCard } from "../card";
import { WalletHistoryRow } from "./row";

import { DepositWithdrawalHistoryResponse } from "@/shared/repository/modules/deposit-withdrawal/repo";

export interface WalletHistoryColumn {
  id: number;
  label: string;
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    WalletCard,
    WalletHistoryRow,
  },
  props: {
    history: {
      type: <PropType<DepositWithdrawalHistoryResponse[]>>Array,
      default: () => [],
    },
  },
  computed: {
    displayedColumns(): WalletHistoryColumn[] {
      return [
        {
          id: 0,
          label: "Дата и время",
        },
        {
          id: 1,
          label: "Тип операции",
        },
        {
          id: 2,
          label: "Сумма",
        },
        {
          id: 3,
          label: "Валюта",
        },
      ];
    },

    displayedHistory(): DepositWithdrawalHistoryResponse[] {
      return this.history
        .slice()
        .sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        .slice(0, 5);
    },
  },
});
