import { defineComponent } from "vue";

import { COMPONENT_NAME } from "./attributes";

import { AdminConfigRequisitesFormBanks } from "./banks";
import { AdminConfigRequisitesFormBank } from "./bank";

import { SharedSelect } from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import {
  AdminRequisiteFactory,
  AdminRequisitesBankStoreRequestFactory,
  AdminRequisitesBankUpdateRequestFactory,
  AdminRequisitesShowResponseFactory,
  AdminRequisitesStoreRequestFactory,
  AdminRequisitesUpdateRequestFactory,
} from "@/shared/repository/modules/admin/requisites/factory";
import { AdminCurrencyListResponseFactory } from "@/shared/repository/modules/admin/currency/factory";
import { SharedSelectOption } from "@/components/shared/select/model";
import { SelectOptionFactory } from "@/shared/factory/select";
import { CountryCurrencies } from "@/shared/constants/constants";
import { AdminCurrencyFiatResponse } from "@/shared/repository/modules/admin/currency/repo";
import { AdminRequisitesBankShowResponse } from "@/shared/repository/modules/admin/requisites/repo";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    AdminConfigRequisitesFormBanks,
    AdminConfigRequisitesFormBank,
    SharedSelect,
    UIInput,
    UIButton,
  },
  data() {
    return {
      bankId: <string | number>"",
      isLoading: false,
      adminRequisitesShow: AdminRequisitesShowResponseFactory(),
      adminCurrencyList: AdminCurrencyListResponseFactory(),
      adminRequisitesRepo: this.$projectServices.adminRequisitesRepo,
      adminRequisitesRepository:
        this.$projectServices.adminRequisitesRepository,
      adminCurrencyRepository: this.$projectServices.adminCurrencyRepository,
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      if (this.adminRequisitesRepo.requisite.id) {
        const [adminRequisitesShow, adminCurrencyList] = await Promise.all([
          this.adminRequisitesRepository.show(
            this.adminRequisitesRepo.requisite.id
          ),
          this.adminCurrencyRepository.list(),
        ]);

        this.adminRequisitesShow = adminRequisitesShow;
        this.adminCurrencyList = adminCurrencyList;
      } else {
        this.adminCurrencyList = await this.adminCurrencyRepository.list();
      }

      this.bankId = this.adminRequisitesShow.mex_requisite_banks[0]?.id ?? "";
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedFiat(): AdminCurrencyFiatResponse[] {
      return this.adminCurrencyList.fiat.filter((fiat) => !fiat.frozen);
    },

    displayedCurrencyOptions(): SharedSelectOption[] {
      return this.displayedFiat
        .filter(
          (fiat) =>
            !this.adminRequisitesRepo.list.find(
              (item) => item.currency.name === fiat.name
            )
        )
        .map((fiat, fiatIndex) =>
          SelectOptionFactory(
            {
              id: fiatIndex,
              label:
                CountryCurrencies[
                  fiat.country as keyof typeof CountryCurrencies
                ]?.name ?? "",
              value: fiat.id,
              icon: fiat.country,
            },
            this.adminRequisitesShow.currency_id
          )
        );
    },

    displayedCurrencyIcon(): string {
      return (
        this.displayedFiat.find(
          (fiat) => fiat.id === this.adminRequisitesShow.currency_id
        )?.country || ""
      );
    },

    displayedBank(): AdminRequisitesBankShowResponse | undefined {
      return this.adminRequisitesShow.mex_requisite_banks.find(
        (bank) => bank.id === this.bankId
      );
    },

    displayedBankIndex(): number {
      return this.adminRequisitesShow.mex_requisite_banks.findIndex(
        (bank) => bank.id === this.bankId
      );
    },

    isCancelDisabled(): boolean {
      return this.isLoading;
    },

    isSaveDisabled(): boolean {
      const {
        currency_id,
        org_full_name_local,
        org_full_name_eng,
        inn,
        payment_code_name,
        mex_requisite_banks,
        current_account,
      } = this.adminRequisitesShow;

      const hasBanks = mex_requisite_banks.some(
        (bank) =>
          !bank.correspondent_bank ||
          !bank.bic ||
          !bank.inn ||
          !bank.correspondent_account ||
          !bank.correspondent_swift ||
          !bank.recipient_bank ||
          !bank.recipient_correspondent_account ||
          !bank.recipient_correspondent_swift
      );

      return (
        !currency_id ||
        !org_full_name_local ||
        !org_full_name_eng ||
        !inn ||
        !payment_code_name ||
        !current_account ||
        hasBanks ||
        this.isLoading
      );
    },
  },
  methods: {
    handleCancel(): void {
      this.adminRequisitesRepo.UPDATE_REQUISITE(
        AdminRequisiteFactory({
          id: 0,
          isShow: false,
        })
      );
    },

    async handleSave(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.adminRequisitesShow.id) {
          await this.adminRequisitesRepository.update(
            AdminRequisitesUpdateRequestFactory(
              {
                ...this.adminRequisitesShow,
                mex_requisite_banks:
                  this.adminRequisitesShow.mex_requisite_banks.map((bank) =>
                    typeof bank.id === "string"
                      ? AdminRequisitesBankStoreRequestFactory(bank)
                      : AdminRequisitesBankUpdateRequestFactory(bank)
                  ),
              },
              false
            )
          );
        } else {
          await this.adminRequisitesRepository.store(
            AdminRequisitesStoreRequestFactory(this.adminRequisitesShow)
          );
        }

        this.adminRequisitesRepo.updateList();

        this.handleCancel();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
