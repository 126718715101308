<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <h1 :class="b('title')">
      {{ $t("История") }}
    </h1>

    <div :class="b('wrapper')">
      <HistoryHeader v-model:history="history" />

      <HistoryTable
        :class="b('table')"
        :history="history"
        @depositWithdrawal="handleDepositWithdrawal"
        @application="handleApplication"
      />

      <SharedButtonIcon
        v-if="isMoreShow"
        :class="b('more')"
        view="gray-outline"
        @click="handleMore"
      >
        <template #icon-left>
          <span :class="b('more-icon')" v-html="displayedMoreIcon"></span>
        </template>

        <template #label>
          {{ $t("Показать больше") }}
        </template>
      </SharedButtonIcon>
    </div>

    <transition name="opacity">
      <HistoryFilters
        :class="b('modal')"
        v-if="isFilter"
        :currency="currency"
        v-model:history="history"
      />
    </transition>

    <transition name="opacity">
      <HistoryDetails
        :class="b('modal')"
        v-if="depositWithdrawal"
        :deposit-withdrawal="depositWithdrawal"
        @depositWithdrawal="handleDepositWithdrawal"
      />
    </transition>

    <transition name="opacity">
      <HistoryApplicationDetails
        :class="b('modal')"
        v-if="application"
        :application="application"
        @application="handleApplication"
      />
    </transition>
  </div>
</template>
