import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('date-time'))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.row.created_at), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('operation'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('group'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label', { from: true }))
        }, _toDisplayString(_ctx.displayedAmountFrom), 3),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.currency_from.name.toUpperCase()), 3)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('icon', { chevron: true })),
        innerHTML: _ctx.displayedSvg.chevron
      }, null, 10, _hoisted_1),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('group'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label', { to: true }))
        }, _toDisplayString(_ctx.displayedAmountTo), 3),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.currency_to.name.toUpperCase()), 3)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('icon', { type: true })),
        innerHTML: _ctx.displayedSvg[_ctx.row.status]
      }, null, 10, _hoisted_2)
    ], 2)
  ], 2))
}