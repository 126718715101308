import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApplicationResult = _resolveComponent("ApplicationResult")!
  const _component_ApplicationConfirm = _resolveComponent("ApplicationConfirm")!
  const _component_ApplicationVerification = _resolveComponent("ApplicationVerification")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("h1", {
      class: _normalizeClass(_ctx.b('title'))
    }, _toDisplayString(_ctx.$t("Создание заявки")), 3),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('wrapper'))
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.displayedComponent), {
        requisites: _ctx.requisites,
        component: _ctx.component,
        "onUpdate:component": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.component) = $event)),
        "is-loading": _ctx.isLoading,
        "onUpdate:isLoading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isLoading) = $event)),
        "is-result": _ctx.isResult,
        "onUpdate:isResult": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isResult) = $event)),
        "is-verification": _ctx.isVerification,
        "onUpdate:isVerification": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isVerification) = $event))
      }, null, 40, ["requisites", "component", "is-loading", "is-result", "is-verification"]))
    ], 2),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isResult)
          ? (_openBlock(), _createBlock(_component_ApplicationResult, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              "is-loading": _ctx.isLoading,
              "onUpdate:isLoading": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isLoading) = $event)),
              "is-result": _ctx.isResult,
              "onUpdate:isResult": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isResult) = $event)),
              "is-confirm": _ctx.isConfirm,
              "onUpdate:isConfirm": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isConfirm) = $event))
            }, null, 8, ["class", "is-loading", "is-result", "is-confirm"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isConfirm)
          ? (_openBlock(), _createBlock(_component_ApplicationConfirm, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              "is-loading": _ctx.isLoading,
              "onUpdate:isLoading": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isLoading) = $event)),
              "is-confirm": _ctx.isConfirm,
              "onUpdate:isConfirm": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isConfirm) = $event))
            }, null, 8, ["class", "is-loading", "is-confirm"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "opacity" }, {
      default: _withCtx(() => [
        (_ctx.isVerification)
          ? (_openBlock(), _createBlock(_component_ApplicationVerification, {
              key: 0,
              class: _normalizeClass(_ctx.b('modal')),
              requisites: _ctx.requisites,
              "onUpdate:requisites": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.requisites) = $event)),
              component: _ctx.component,
              "onUpdate:component": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.component) = $event)),
              "is-verification": _ctx.isVerification,
              "onUpdate:isVerification": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isVerification) = $event))
            }, null, 8, ["class", "requisites", "component", "is-verification"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}