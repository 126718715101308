<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <tr :class="b('', { disabled: isAdminShow })">
    <td>
      <div :class="b('created-at')">
        <p :class="b('label')">
          {{ row.created_at }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('user-id')">
        <p :class="b('label')">
          {{ row.user_id }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('email')">
        <p :class="b('label')">
          {{ row.user.email }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('type')">
        <div :class="b('operation')">
          <p :class="b('label', { from: true })">
            {{ displayedAmountFrom }}
          </p>

          <p :class="b('label')">
            {{ row.currency_from.name.toUpperCase() }}
          </p>
        </div>

        <span
          :class="b('icon', { chevron: true })"
          v-html="displayedSvg.chevron"
        ></span>

        <div :class="b('operation')">
          <p :class="b('label', { to: true })">
            {{ displayedAmountTo }}
          </p>

          <p :class="b('label')">
            {{ row.currency_to.name.toUpperCase() }}
          </p>
        </div>
      </div>
    </td>

    <td>
      <div :class="b('execution-price')">
        <p :class="b('label')">
          {{ displayedExecutionPrice }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('transaction-volume')">
        <p :class="b('label')">
          {{ displayedTransactionVolume }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('commissions')">
        <p :class="b('label')">
          {{ displayedCommission }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('number')">
        <p :class="b('label')">
          {{ row.id }}
        </p>
      </div>
    </td>

    <td>
      <div :class="b('status')">
        <span :class="b('icon')" v-html="displayedSvg[row.status]" />

        <p :class="b('label')">
          {{ displayedStatus }}
        </p>
      </div>
    </td>

    <td v-if="!isAdminShow">
      <div :class="b('stage')">
        <div v-if="isStageGroupShow && !isAdminShow" :class="b('stage-group')">
          <button :class="b('stage-work')" @click="handleInWork">
            {{ $t("В работу") }}
          </button>

          <button
            :class="b('action')"
            :disabled="isDisabled"
            @click="handleRemove"
          >
            <span :class="b('icon')" v-html="displayedSvg.remove"></span>
          </button>
        </div>

        <p v-else :class="b('label')">
          {{ row.stage }}
        </p>

        <div v-if="!isAdminShow" :class="b('action')">
          <button
            :class="b('toggle', { active: isVisible })"
            @click="handleToggle"
          >
            <span :class="b('toggle-icon')" v-html="displayedSvg.dots"></span>
          </button>

          <div v-if="isVisible" :class="b('options', { active: isVisible })">
            <button
              v-for="option in displayedOptions"
              :key="option.id"
              :class="b('option')"
              :disabled="option.isDisabled"
              @click="handleOption(option)"
            >
              {{ option.label }}
            </button>
          </div>
        </div>
      </div>
    </td>

    <td v-else>
      <span :class="b('admin')">
        {{ row.admin.email }}
      </span>
    </td>
  </tr>
</template>
