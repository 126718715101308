import moment from "moment";
import { AdminCurrencyType } from "../currency/enums";
import {
  AdminApplicationListResponse,
  AdminApplicationShowResponse,
  AdminApplicationUpdateRequest,
} from "./repo";
import { AdminCurrencyShowResponseFactory } from "../currency/factory";

import { ApplicationStatus } from "@/shared/constants/enums";
import { UserResponseFactory } from "../../user/factory";

export const AdminApplicationUpdateRequestFactory = (
  payload: Partial<AdminApplicationUpdateRequest> = {}
): AdminApplicationUpdateRequest => {
  return {
    amount: payload.amount ?? 0,
    stage: payload.stage ?? "",
    commissions: payload.commissions ?? 0,
    transaction_volume: payload.transaction_volume ?? 0,
    execution_price: payload.execution_price ?? 0,
  };
};

export const AdminApplicationShowResponseFactory = (
  payload: Partial<AdminApplicationShowResponse> = {}
): AdminApplicationShowResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    currency_type_from: payload.currency_type_from ?? AdminCurrencyType.fiat,
    currency_type_to: payload.currency_type_to ?? AdminCurrencyType.fiat,
    currency_id_from: payload.currency_id_from ?? 0,
    currency_id_to: payload.currency_id_to ?? 0,
    user_requisite_id_from: payload.user_requisite_id_from ?? 0,
    user_requisite_type_from: payload.user_requisite_type_from ?? "",
    user_requisite_id_to: payload.user_requisite_id_to ?? 0,
    user_requisite_type_to: payload.user_requisite_type_to ?? "",
    amount: Number(payload.amount ?? 0),
    execution_price: Number(payload.execution_price ?? 0),
    transaction_volume: Number(payload.transaction_volume ?? 0),
    commissions: Number(payload.commissions ?? 0),
    status: payload.status ?? ApplicationStatus.pending,
    stage: payload.stage ?? "",
    admin_id: payload.admin_id ?? 0,
    deleted_at: payload.deleted_at ?? "",
    created_at: moment(payload.created_at ?? "").format("YYYY-MM-DD HH:mm:ss"),
    updated_at: payload.updated_at ?? "",
    user: UserResponseFactory(payload.user ?? {}),
    currency_from: AdminCurrencyShowResponseFactory(
      payload.currency_from ?? {}
    ),
    currency_to: AdminCurrencyShowResponseFactory(payload.currency_to ?? {}),
  };
};

export const AdminApplicationListResponseFactory = (
  payload: Partial<AdminApplicationListResponse> = {}
): AdminApplicationListResponse => {
  return {
    id: payload.id ?? 0,
    user_id: payload.user_id ?? 0,
    currency_type_from: payload.currency_type_from ?? AdminCurrencyType.fiat,
    currency_type_to: payload.currency_type_to ?? AdminCurrencyType.fiat,
    currency_id_from: payload.currency_id_from ?? 0,
    currency_id_to: payload.currency_id_to ?? 0,
    user_requisite_id_from: payload.user_requisite_id_from ?? 0,
    user_requisite_type_from: payload.user_requisite_type_from ?? "",
    user_requisite_id_to: payload.user_requisite_id_to ?? 0,
    user_requisite_type_to: payload.user_requisite_type_to ?? "",
    amount: Number(payload.amount ?? 0),
    execution_price: Number(payload.execution_price ?? 0),
    transaction_volume: Number(payload.transaction_volume ?? 0),
    commissions: Number(payload.commissions ?? 0),
    status: payload.status ?? ApplicationStatus.pending,
    stage: payload.stage ?? "",
    admin_id: payload.admin_id ?? 0,
    deleted_at: payload.deleted_at ?? "",
    created_at: moment(payload.created_at ?? "").format("YYYY-MM-DD HH:mm:ss"),
    updated_at: payload.updated_at ?? "",
    currency_from: AdminCurrencyShowResponseFactory(
      payload.currency_from ?? {}
    ),
    currency_to: AdminCurrencyShowResponseFactory(payload.currency_to ?? {}),
    user: UserResponseFactory(payload.user ?? {}),
    admin: UserResponseFactory(payload.admin ?? {}),
  };
};
