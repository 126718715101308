/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  DepositWithdrawalDeposit,
  DepositWithdrawalStoreRequest,
} from "@/shared/repository/modules/deposit-withdrawal/repo";
import { DepositWithdrawalDepositFactory } from "@/shared/repository/modules/deposit-withdrawal/factory";

enum DepositWithdrawalMutation {
  UPDATE_DEPOSIT = "UPDATE_DEPOSIT",
  UPDATE_APPLICATION = "UPDATE_APPLICATION",
  CLEAR_APPLICATION = "CLEAR_APPLICATION",
}

interface State {
  deposit?: DepositWithdrawalDeposit;
  application?: DepositWithdrawalStoreRequest | null;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "depositWithdrawal",
})
export default class DepositWithdrawalModuleState extends VuexModule<State> {
  deposit: DepositWithdrawalDeposit = DepositWithdrawalDepositFactory();
  application: DepositWithdrawalStoreRequest | null = null;

  @Mutation
  [DepositWithdrawalMutation.UPDATE_DEPOSIT](
    deposit: DepositWithdrawalDeposit
  ): void {
    this.deposit = DepositWithdrawalDepositFactory(deposit);
  }

  @Mutation
  [DepositWithdrawalMutation.UPDATE_APPLICATION](
    application: DepositWithdrawalStoreRequest
  ): void {
    this.application = application;
  }

  @Mutation
  [DepositWithdrawalMutation.CLEAR_APPLICATION](): void {
    this.application = null;
  }
}
