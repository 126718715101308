import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";
import { HistoryTab, RoutesName } from "@/shared/constants/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  props: {
    title: {
      type: String,
      default: "",
    },
    tab: {
      type: <PropType<HistoryTab>>String,
      required: true,
    },
  },
  data() {
    return {
      historyRepo: this.$projectServices.historyRepo,
    };
  },
  methods: {
    handleHistory(): void {
      this.historyRepo.UPDATE_TAB(this.tab);
      this.historyRepo.CLEAR_FILTER();

      this.$router.push(RoutesName.history);
    },
  },
});
