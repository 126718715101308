import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["disabled", "onClick"]
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(_ctx.b('', { disabled: _ctx.isAdminShow }))
  }, [
    _createElementVNode("td", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('created-at'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.displayedCreatedAt), 3)
      ], 2)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('user-id'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.user_id), 3)
      ], 2)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('email'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.user.email), 3)
      ], 2)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('app-type'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.displayedAppType), 3)
      ], 2)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('amount'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(
              _ctx.b('label-amount', {
                deposit: _ctx.isDeposit,
                withdrawal: _ctx.isWithdrawal,
              })
            )
          }, _toDisplayString(_ctx.displayedAmount), 3)
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.currency.name.toUpperCase()), 3)
      ], 2)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('execution-price'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.execution_price), 3)
      ], 2)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('transaction-volume'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.transaction_volume), 3)
      ], 2)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('commissions'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.commissions), 3)
      ], 2)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('number'))
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.row.id), 3)
      ], 2)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('status'))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('icon')),
          innerHTML: _ctx.displayedStatusIcon
        }, null, 10, _hoisted_1),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.b('label'))
        }, _toDisplayString(_ctx.displayedStatus), 3)
      ], 2)
    ]),
    (!_ctx.isAdminShow)
      ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('stage'))
          }, [
            (_ctx.isStageGroupShow)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.b('stage-group'))
                }, [
                  _createElementVNode("button", {
                    class: _normalizeClass(_ctx.b('stage-work')),
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInWork && _ctx.handleInWork(...args)))
                  }, _toDisplayString(_ctx.$t("В работу")), 3),
                  _createElementVNode("button", {
                    class: _normalizeClass(_ctx.b('action')),
                    disabled: _ctx.isDisabled,
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleRemove && _ctx.handleRemove(...args)))
                  }, [
                    _createElementVNode("span", {
                      class: _normalizeClass(_ctx.b('icon')),
                      innerHTML: _ctx.displayedSvg.remove
                    }, null, 10, _hoisted_4)
                  ], 10, _hoisted_3)
                ], 2))
              : (_openBlock(), _createElementBlock("p", {
                  key: 1,
                  class: _normalizeClass(_ctx.b('label'))
                }, _toDisplayString(_ctx.row.stage), 3)),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('action'))
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.b('toggle', { active: _ctx.isVisible })),
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleToggle && _ctx.handleToggle(...args)))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.b('toggle-icon')),
                  innerHTML: _ctx.displayedSvg.dots
                }, null, 10, _hoisted_5)
              ], 2),
              (_ctx.isVisible)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.b('options', { active: _ctx.isVisible }))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedOptions, (option) => {
                      return (_openBlock(), _createElementBlock("button", {
                        key: option.id,
                        class: _normalizeClass(_ctx.b('option')),
                        disabled: option.isDisabled,
                        onClick: ($event: any) => (_ctx.handleOption(option))
                      }, _toDisplayString(option.label), 11, _hoisted_6))
                    }), 128))
                  ], 2))
                : _createCommentVNode("", true)
            ], 2)
          ], 2)
        ]))
      : (_openBlock(), _createElementBlock("td", _hoisted_7, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('admin'))
          }, _toDisplayString(_ctx.row.admin.email), 3)
        ]))
  ], 2))
}