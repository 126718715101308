/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

import { HistoryTab } from "@/shared/constants/enums";
import { HistoryFilterRequest, Search } from "@/shared/constants/interfaces";
import { SearchFactory } from "@/shared/factory/search";
import { HistoryFilterRequestFactory } from "@/shared/factory/history";

enum HistoryMutation {
  UPDATE_FILTER = "UPDATE_FILTER",
  CLEAR_FILTER = "CLEAR_FILTER",
  UPDATE_TAB = "UPDATE_TAB",
  UPDATE_IS_FILTER = "UPDATE_IS_FILTER",
  UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE",
  ADD_PAGE = "ADD_PAGE",
  CLEAR_PAGE = "CLEAR_PAGE",
  CLEAR_TYPE = "CLEAR_TYPE",
}

interface State {
  filter?: HistoryFilterRequest;
  tab?: HistoryTab;
  isFilter?: boolean;
  search?: Search;
  isLoading?: boolean;
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "history",
})
export default class HistoryModuleState extends VuexModule<State> {
  filter: HistoryFilterRequest = HistoryFilterRequestFactory();
  tab: HistoryTab = HistoryTab.depositWithdrawal;
  isFilter: boolean = false;
  search: Search = SearchFactory();

  @Mutation
  [HistoryMutation.UPDATE_FILTER](filter: HistoryFilterRequest): void {
    this.filter = filter;
  }

  @Mutation
  [HistoryMutation.CLEAR_FILTER](): void {
    this.filter = HistoryFilterRequestFactory();
  }

  @Mutation
  [HistoryMutation.UPDATE_TAB](tab: HistoryTab): void {
    this.tab = tab;
  }

  @Mutation
  [HistoryMutation.UPDATE_IS_FILTER](isFilter: boolean): void {
    this.isFilter = isFilter;
  }

  @Mutation
  [HistoryMutation.UPDATE_SEARCH_VALUE](value: string): void {
    this.search.value = value;
  }

  @Mutation
  [HistoryMutation.ADD_PAGE](): void {
    this.filter.page += 1;
  }

  @Mutation
  [HistoryMutation.CLEAR_PAGE](): void {
    this.filter.page = 1;
  }

  @Mutation
  [HistoryMutation.CLEAR_TYPE](): void {
    this.filter.type = "";
  }
}
