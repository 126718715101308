<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('form')">
      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("Валюта") }}
          </h4>

          <SharedSelect
            view="light"
            :options="displayedCurrencyOptions"
            :icon="displayedCurrencyIcon"
            v-model:value="adminRequisitesShow.currency_id"
          />
        </div>
      </div>

      <div :class="b('fields')">
        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{
              $t("Полное фирменное наименование Общества на официальном языке")
            }}
          </h4>

          <UIInput
            view="light"
            v-model="adminRequisitesShow.org_full_name_local"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{
              $t("Полное фирменное наименование Общества на английском языке")
            }}
          </h4>

          <UIInput
            view="light"
            v-model="adminRequisitesShow.org_full_name_eng"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("ИНН") }}
          </h4>

          <UIInput view="light" v-model="adminRequisitesShow.inn" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("Р/с № (мультивалютный)") }}
          </h4>

          <UIInput view="light" v-model="adminRequisitesShow.current_account" />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("Код назначения платежа") }}
          </h4>

          <UIInput
            view="light"
            v-model="adminRequisitesShow.payment_code_name"
          />
        </div>

        <div :class="b('field')">
          <h4 :class="b('field-title')">
            {{ $t("Назначение платежа") }}
          </h4>

          <UIInput view="light" :is-disabled="true" />
        </div>
      </div>
    </div>

    <AdminConfigRequisitesFormBanks
      v-model:bankId="bankId"
      v-model:banks="adminRequisitesShow.mex_requisite_banks"
    />

    <AdminConfigRequisitesFormBank
      v-if="displayedBank"
      v-model:correspondentBank="
        adminRequisitesShow.mex_requisite_banks[displayedBankIndex]
          .correspondent_bank
      "
      v-model:bic="
        adminRequisitesShow.mex_requisite_banks[displayedBankIndex].bic
      "
      v-model:inn="
        adminRequisitesShow.mex_requisite_banks[displayedBankIndex].inn
      "
      v-model:correspondentAccount="
        adminRequisitesShow.mex_requisite_banks[displayedBankIndex]
          .correspondent_account
      "
      v-model:correspondentSwift="
        adminRequisitesShow.mex_requisite_banks[displayedBankIndex]
          .correspondent_swift
      "
      v-model:recipientBank="
        adminRequisitesShow.mex_requisite_banks[displayedBankIndex]
          .recipient_bank
      "
      v-model:recipientCorrespondentAccount="
        adminRequisitesShow.mex_requisite_banks[displayedBankIndex]
          .recipient_correspondent_account
      "
      v-model:recipientCorrespondentSwift="
        adminRequisitesShow.mex_requisite_banks[displayedBankIndex]
          .recipient_correspondent_swift
      "
    />

    <div :class="b('buttons')">
      <UIButton
        view="main-outline"
        label="Назад"
        :disabled="isCancelDisabled"
        @click="handleCancel"
      />

      <UIButton
        view="main"
        label="Сохранить"
        :disabled="isSaveDisabled"
        @click="handleSave"
      />
    </div>
  </div>
</template>
