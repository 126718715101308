<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <div :class="b()">
    <div :class="b('tabs')">
      <button
        :class="b('tab', { active: tab.acitve })"
        v-for="tab in displayedTabs"
        :key="tab.id"
        :disabled="tab.isDisabled"
        @click="handleTab(tab)"
      >
        {{ $t(tab.label) }}
      </button>
    </div>

    <SharedSearch :search="search" @update:search="handleSearch" />
  </div>
</template>
