import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedCryptoSelect = _resolveComponent("SharedCryptoSelect")!
  const _component_SharedSelect = _resolveComponent("SharedSelect")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_SharedCurrency = _resolveComponent("SharedCurrency")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('fields'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('scores'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('score'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('title'))
          }, _toDisplayString(_ctx.$t("Со счета")), 3),
          _createVNode(_component_SharedCryptoSelect, {
            placeholder: _ctx.$t('Выберите из пресетов'),
            options: _ctx.displayedFromOptions,
            value: _ctx.displayedApplicationStoreRequest.currency_id_from,
            "onUpdate:value": _ctx.handleCurrencyIdFrom,
            onCreate: _ctx.handleCryptoCreate
          }, null, 8, ["placeholder", "options", "value", "onUpdate:value", "onCreate"]),
          (_ctx.isRequisitesFromShow)
            ? (_openBlock(), _createBlock(_component_SharedSelect, {
                key: 0,
                class: _normalizeClass(_ctx.b('requisites')),
                view: "light",
                placeholder: _ctx.$t('Выберите реквизиты'),
                options: _ctx.displayedRequisitesFromOptions,
                value: 
              _ctx.displayedApplicationStoreRequest.user_requisite_id_from
            ,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((
              _ctx.displayedApplicationStoreRequest.user_requisite_id_from
            ) = $event))
              }, null, 8, ["class", "placeholder", "options", "value"]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.b('scores-icon')),
          innerHTML: _ctx.displayedArrowIcon
        }, null, 10, _hoisted_1),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('score'))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('score'))
          }, [
            _createElementVNode("h4", {
              class: _normalizeClass(_ctx.b('title'))
            }, _toDisplayString(_ctx.$t("На счет")), 3),
            _createVNode(_component_SharedCryptoSelect, {
              placeholder: _ctx.$t('Выберите из пресетов / создайте новый'),
              options: _ctx.displayedToOptions,
              value: _ctx.displayedApplicationStoreRequest.currency_id_to,
              "onUpdate:value": _ctx.handleCurrencyIdTo,
              onCreate: _ctx.handleCryptoCreate
            }, null, 8, ["placeholder", "options", "value", "onUpdate:value", "onCreate"]),
            (_ctx.isRequisitesToShow)
              ? (_openBlock(), _createBlock(_component_SharedSelect, {
                  key: 0,
                  class: _normalizeClass(_ctx.b('requisites')),
                  view: "light",
                  placeholder: _ctx.$t('Выберите реквизиты'),
                  options: _ctx.displayedRequisitesToOptions,
                  value: 
                _ctx.displayedApplicationStoreRequest.user_requisite_id_to
              ,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((
                _ctx.displayedApplicationStoreRequest.user_requisite_id_to
              ) = $event))
                }, null, 8, ["class", "placeholder", "options", "value"]))
              : _createCommentVNode("", true)
          ], 2),
          (_ctx.isCommissionsShow)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.b('message', { amount: true }))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.b('message-item'))
                }, [
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('message-label'))
                  }, _toDisplayString(_ctx.$t("Текущий курс")), 3),
                  _createElementVNode("p", {
                    class: _normalizeClass(_ctx.b('message-label'))
                  }, " ~ " + _toDisplayString(_ctx.displayedCourse) + " " + _toDisplayString(_ctx.displayedCurrencyTo?.name.toUpperCase()), 3)
                ], 2)
              ], 2))
            : _createCommentVNode("", true)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('amount'))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.b('amount-group'))
        }, [
          _createElementVNode("h4", {
            class: _normalizeClass(_ctx.b('title'))
          }, _toDisplayString(_ctx.$t("Сумма")), 3),
          _createVNode(_component_UIButton, {
            label: "МАКС",
            view: "max",
            onClick: _ctx.handleMax
          }, null, 8, ["onClick"])
        ], 2),
        _createElementVNode("div", null, [
          _createVNode(_component_SharedCurrency, {
            label: _ctx.displayedCurrencyName.toUpperCase(),
            value: _ctx.displayedApplicationStoreRequest.amount,
            "max-value": _ctx.displayedApplicationStoreRequest.max_amount,
            "is-number": true,
            "is-max-value": true,
            "is-error": 
              _ctx.displayedApplicationStoreRequest.currency_type_from === 'fiat' &&
              _ctx.displayedApplicationStoreRequest.currency_type_to === 'fiat' &&
              _ctx.displayedApplicationStoreRequest.amount >
                _ctx.displayedApplicationStoreRequest.max_amount
            ,
            "onUpdate:value": _ctx.handleAmount
          }, null, 8, ["label", "value", "max-value", "is-error", "onUpdate:value"]),
          (
              _ctx.displayedApplicationStoreRequest.currency_type_from === 'fiat' &&
              _ctx.displayedApplicationStoreRequest.currency_type_to === 'fiat' &&
              _ctx.displayedApplicationStoreRequest.amount >
                _ctx.displayedApplicationStoreRequest.max_amount
            )
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: _normalizeClass(_ctx.b('message-error'))
              }, " Не хватает средств ", 2))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isCommissionsShow)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.b('message', { amount: true }))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('message-item'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('message-label'))
                }, _toDisplayString(_ctx.$t("Комиссия")), 3),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('message-label'))
                }, " ~ " + _toDisplayString(_ctx.displayedCommission) + " " + _toDisplayString(_ctx.displayedCurrencyFrom?.currency?.name.toUpperCase()), 3)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('message-item'))
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('message-label'))
                }, _toDisplayString(_ctx.$t("Вы получите")), 3),
                _createElementVNode("p", {
                  class: _normalizeClass(_ctx.b('message-label'))
                }, " ~ " + _toDisplayString(_ctx.displayedAmount) + " " + _toDisplayString(_ctx.displayedCurrencyTo?.currency?.name.toUpperCase() ||
                _ctx.displayedCurrencyTo?.name.toUpperCase()), 3)
              ], 2)
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 2),
    _createVNode(_component_UIButton, {
      label: "Далее",
      view: "main",
      disabled: _ctx.isNextDisabled,
      onClick: _ctx.handleNext
    }, null, 8, ["disabled", "onClick"])
  ], 2))
}