import { defineComponent } from "vue";

import { COMPONENT_NAME, SVG } from "./attributes";

import { UIButton } from "@/components/ui";
import { SharedWindow } from "@/components/shared";
import {
  WalletListResponse,
  WalletWithAmountResponse,
} from "@/shared/repository/modules/wallet/repo";
import { ApplicationStoreRequest } from "@/shared/repository/modules/application/repo";
import { SvgAttribute } from "@/shared/constants/constants";
import { formatFiatCurrency } from "@/shared/utils/amount-helpers";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    UIButton,
    SharedWindow,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isResult: {
      type: Boolean,
      default: false,
    },
    isConfirm: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    "update:isLoading": null,
    "update:isResult": null,
    "update:isConfirm": null,
  },
  data() {
    return {
      userRepo: this.$projectServices.userRepo,
      walletRepo: this.$projectServices.walletRepo,
      applicationRepo: this.$projectServices.applicationRepo,
      applicationRepository: this.$projectServices.applicationRepository,
    };
  },
  computed: {
    displayedWalletList(): WalletListResponse[] {
      return this.walletRepo.list;
    },

    displayedWalletWithAmount(): WalletWithAmountResponse[] {
      return this.walletRepo.withAmount;
    },

    displayedApplicationStoreRequest(): ApplicationStoreRequest {
      return this.applicationRepo.application;
    },

    displayedSvg(): SvgAttribute {
      return SVG;
    },

    displayedCurrencyFrom(): WalletWithAmountResponse {
      const { currency_id_from } = this.displayedApplicationStoreRequest;

      return this.displayedWalletWithAmount.find(
        (currencyItem) => currencyItem.currency.id === currency_id_from
      )!;
    },

    displayedCurrencyTo(): WalletListResponse {
      const { currency_id_to } = this.displayedApplicationStoreRequest;

      return this.displayedWalletList.find(
        (currencyItem) => currencyItem.currency_id === currency_id_to
      )!;
    },

    displayedCommissions(): string {
      return `~ ${
        this.displayedCommission
      } ${this.displayedCurrencyFrom.currency.name.toUpperCase()}`;
    },

    displayedCourse(): string {
      return formatFiatCurrency(
        this.displayedApplicationStoreRequest.execution_price
      );
    },

    displayedCommission(): string {
      return formatFiatCurrency(
        this.displayedApplicationStoreRequest.commissions
      );
    },

    displayedAmountFrom(): string {
      return formatFiatCurrency(this.displayedApplicationStoreRequest.amount);
    },

    displayedAmountTo(): string {
      const { amount, commissions, execution_price } =
        this.displayedApplicationStoreRequest;

      return formatFiatCurrency((amount - commissions) * execution_price);
    },
  },
  methods: {
    handleCopy(): void {
      console.log("Handle Copy");
    },

    handleClose(): void {
      if (!this.isLoading) {
        this.$emit("update:isResult", false);
      }
    },

    async handleCreate(): Promise<void> {
      try {
        this.$emit("update:isLoading", true);

        const {
          currency_id_from,
          currency_id_to,
          currency_type_from,
          currency_type_to,
          amount,
          max_amount,
          commissions,
          execution_price,
          transaction_volume,
          user_requisite_id_from,
          user_requisite_id_to,
          user_requisite_type_from,
          user_requisite_type_to,
        } = this.displayedApplicationStoreRequest;

        if (
          currency_type_from === AdminCurrencyType.fiat &&
          currency_type_to === AdminCurrencyType.fiat
        ) {
          await this.applicationRepository.store(
            {
              currency_id_from,
              currency_id_to,
              currency_type_from,
              currency_type_to,
              amount,
              commissions,
              execution_price,
              transaction_volume,
              max_amount,
            },
            this.userRepo.userInfo.id
          );
        } else if (
          currency_type_from === AdminCurrencyType.crypto ||
          currency_type_to === AdminCurrencyType.fiat
        ) {
          await this.applicationRepository.store(
            {
              currency_id_from,
              currency_id_to,
              currency_type_from,
              currency_type_to,
              amount,
              commissions,
              execution_price,
              transaction_volume,
              max_amount,
              user_requisite_id_from,
              user_requisite_type_from,
            },
            this.userRepo.userInfo.id
          );
        } else if (
          currency_type_from === AdminCurrencyType.fiat ||
          currency_type_to === AdminCurrencyType.crypto
        ) {
          await this.applicationRepository.store(
            {
              currency_id_from,
              currency_id_to,
              currency_type_from,
              currency_type_to,
              amount,
              commissions,
              execution_price,
              transaction_volume,
              max_amount,
              user_requisite_id_to,
              user_requisite_type_to,
            },
            this.userRepo.userInfo.id
          );
        }

        if (
          currency_type_from === AdminCurrencyType.crypto ||
          currency_type_to === AdminCurrencyType.crypto
        ) {
          await this.applicationRepository.store(
            {
              currency_id_from,
              currency_id_to,
              currency_type_from,
              currency_type_to,
              amount,
              commissions,
              execution_price,
              transaction_volume,
              max_amount,
              user_requisite_id_from,
              user_requisite_type_from,
              user_requisite_id_to,
              user_requisite_type_to,
            },
            this.userRepo.userInfo.id
          );
        }

        this.$emit("update:isResult", false);
        this.$emit("update:isConfirm", true);
      } catch (error) {
        console.log(error);
      } finally {
        this.$emit("update:isLoading", false);
      }
    },
  },
});
