import { defineComponent } from "vue";

import { PAGE_NAME } from "./attributes";
import { AdminKycComponent, AdminKycHeaderFactory } from "./model";

import { SharedAdminHeader } from "@/components/shared";
import {
  AdminKycWrapper,
  AdminKycNext,
  AdminKycStatus,
  AdminKycPersonalInformation,
  AdminKycPersonalDocuments,
  AdminKycKybCompany,
  AdminKycKybBasicInformation,
  AdminKycKybRegistrationInformation,
  AdminKycKybParticipants,
  AdminKycKybParticipant,
  AdminKycKybParticipantDocuments,
  AdminKycKybDocuments,
} from "@/components/pages/admin-kyc";
import { AdminUsersResponse } from "@/shared/repository/modules/admin/users/repo";
import { AdminUserAppResponseFactory } from "@/shared/repository/modules/admin/user-app/factory";
import { ApplicationType } from "@/shared/constants/enums";

type Component =
  | typeof AdminKycPersonalInformation
  | typeof AdminKycPersonalDocuments
  | typeof AdminKycKybCompany
  | typeof AdminKycKybBasicInformation
  | typeof AdminKycKybRegistrationInformation
  | typeof AdminKycKybParticipants
  | typeof AdminKycKybParticipant
  | typeof AdminKycKybParticipantDocuments
  | typeof AdminKycKybDocuments;
type FooterComponent = typeof AdminKycNext | typeof AdminKycStatus;

export default defineComponent({
  name: PAGE_NAME,
  components: {
    SharedAdminHeader,
    AdminKycWrapper,
    AdminKycNext,
    AdminKycStatus,
    AdminKycPersonalInformation,
    AdminKycPersonalDocuments,
    AdminKycKybCompany,
  },
  data() {
    return {
      isLoading: false,
      component: AdminKycComponent.personalInformation,
      adminKycHeader: AdminKycHeaderFactory(),
      adminUserAppResponse: AdminUserAppResponseFactory(),
      usersRepo: this.$projectServices.usersRepo,
      adminUserAppRepository: this.$projectServices.adminUserAppRepository,
    };
  },
  async created(): Promise<void> {
    const { index } = this.$route.query;

    this.usersRepo.UPDATE_INDEX(Number(index));

    if (this.displayedUser) {
      const { kyc_status } = this.displayedUser;

      this.adminKycHeader = AdminKycHeaderFactory({
        id: Number(index),
        type: kyc_status ? ApplicationType.kyc : ApplicationType.kyb,
      });

      await this.handleSearch();
    }
  },
  computed: {
    displayedComponent(): Component {
      switch (this.component) {
        case AdminKycComponent.personalInformation:
          return AdminKycPersonalInformation;
        case AdminKycComponent.personalDocuments:
          return AdminKycPersonalDocuments;
        case AdminKycComponent.kybCompany:
          return AdminKycKybCompany;
        case AdminKycComponent.kybBasicInformation:
          return AdminKycKybBasicInformation;
        case AdminKycComponent.kybRegistrationInformation:
          return AdminKycKybRegistrationInformation;
        case AdminKycComponent.kybParticipants:
          return AdminKycKybParticipants;
        case AdminKycComponent.kybParticipant:
          return AdminKycKybParticipant;
        case AdminKycComponent.kybParticipantDocuments:
          return AdminKycKybParticipantDocuments;
        case AdminKycComponent.kybDocuments:
          return AdminKycKybDocuments;
        default:
          return AdminKycPersonalInformation;
      }
    },

    displayedFooterComponent(): FooterComponent {
      if (
        this.component === AdminKycComponent.personalDocuments ||
        this.component === AdminKycComponent.kybCompany ||
        this.component === AdminKycComponent.kybDocuments
      ) {
        return AdminKycStatus;
      }

      return AdminKycNext;
    },

    displayedUser(): AdminUsersResponse | undefined {
      return this.usersRepo.userByIndex;
    },

    isWrapperShow(): boolean {
      return !!this.adminUserAppResponse.id;
    },

    isFooterShow(): boolean {
      return !!this.adminUserAppResponse.id;
    },
  },
  methods: {
    async handleSearch(): Promise<void> {
      try {
        this.isLoading = true;

        this.adminUserAppResponse = AdminUserAppResponseFactory();

        this.usersRepo.UPDATE_INDEX(this.adminKycHeader.id);

        const user = this.displayedUser;

        if (user && (user.kyc_id || user.kyb_id)) {
          const adminUserAppResponse = await this.adminUserAppRepository.app(
            user.kyc_id || user.kyb_id || 0,
            this.adminKycHeader.type
          );

          this.adminUserAppResponse = AdminUserAppResponseFactory({
            ...adminUserAppResponse,
            index: this.adminKycHeader.id,
            type: this.adminKycHeader.type,
          });

          switch (this.adminKycHeader.type) {
            case ApplicationType.kyc:
              this.component = AdminKycComponent.personalInformation;

              break;
            case ApplicationType.kyb:
              this.component = AdminKycComponent.kybCompany;

              break;
            default:
              break;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
