import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedUpload,
  SharedDownload,
  SharedValidate,
} from "@/components/shared";
import { UIButton } from "@/components/ui";

import { VerificationKycComponent } from "@/views/verification/model";
import {
  KycPersonalInfoResponseFactory,
  KycUploadFileRequestFactory,
  KycUploadFileResponseFactory,
} from "@/shared/repository/modules/kyc/factory";
import {
  FileFor,
  FileType,
  KycDocumentsFileName,
} from "@/shared/constants/enums";
import { FileParams, KycDocuments } from "@/shared/constants/interfaces";
import { extractFileName } from "@/shared/utils/file-name-helpers";
import { KycUploadFileResponse } from "@/shared/repository/modules/kyc/repo";
import { ParserParseRequestFactory } from "@/shared/repository/modules/parser/factory";
import { ParserDocument } from "@/shared/repository/modules/parser/enums";
import { ParserParseRequest } from "@/shared/repository/modules/parser/repo";
import { ModalFactory } from "@/shared/factory/modal";
import {
  ModalIcon,
  ModalLabel,
  ModalTitle,
} from "@/shared/constants/constants";
import { UserFileResponse } from "@/shared/repository/modules/user/repo";
import {
  UserFileRequestFactory,
  UserFileResponseFactory,
} from "@/shared/repository/modules/user/factory";
import { FILES_LENGTH } from "@/shared/repository/modules/user/constants";

enum VerificationLabel {
  "Отправлено на проверку" = "Отправлено на проверку",
  "Отправить на проверку" = "Отправить на проверку",
}

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedUpload,
    SharedDownload,
    SharedValidate,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<VerificationKycComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      modalRepo: this.$projectServices.modalRepo,
      userRepo: this.$projectServices.userRepo,
      userRepository: this.$projectServices.userRepository,
      kycRepository: this.$projectServices.kycRepository,
      kycPersonalInfo: KycPersonalInfoResponseFactory(),
      file: UserFileResponseFactory(),
      documents: Object.keys(KycDocumentsFileName).reduce(
        (documents, fileName) => {
          documents[fileName] = KycUploadFileResponseFactory({
            name: KycDocumentsFileName[fileName as KycDocumentsFileName],
          });

          return documents;
        },
        {} as KycDocuments
      ) as KycDocuments,
      files: <UserFileResponse[]>[],
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      const [kycPersonalInfo, userFiles, files] = await Promise.allSettled([
        this.kycRepository.personalInfo(this.userRepo.userInfo.id),
        this.kycRepository.userFiles(this.userRepo.userInfo.id),
        this.userRepository.files(FileFor.kyc, this.userRepo.userInfo.id),
      ]);

      if (kycPersonalInfo.status === "fulfilled") {
        this.kycPersonalInfo = kycPersonalInfo.value;
      }

      if (userFiles.status === "fulfilled") {
        userFiles.value.forEach((userFile) => {
          this.documents[extractFileName(userFile.name)] =
            KycUploadFileResponseFactory(userFile);
        });
      }

      if (files.status === "fulfilled") {
        this.files = files.value;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedVerificationLabel(): VerificationLabel {
      return this.userRepo.isReadonlyKyc
        ? VerificationLabel["Отправлено на проверку"]
        : VerificationLabel["Отправить на проверку"];
    },

    displayedQuestionnaireOfAnIndividual(): ParserParseRequest {
      return ParserParseRequestFactory(
        this.kycPersonalInfo,
        ParserDocument["Анкета физического лица"]
      );
    },

    displayedConsentToTheProcessingOfPersonalData(): ParserParseRequest {
      return ParserParseRequestFactory(
        this.kycPersonalInfo,
        ParserDocument["Согласие на обработку персональных данных"]
      );
    },

    displayedRiskDeclaration(): ParserParseRequest {
      return ParserParseRequestFactory(
        this.kycPersonalInfo,
        ParserDocument["Декларация о рисках"]
      );
    },

    displayedBrokerageAgreement(): ParserParseRequest {
      const { id, email } = this.userRepo.userInfo;

      return ParserParseRequestFactory(
        {
          ...this.kycPersonalInfo,
          id: id,
          email: email,
        },
        ParserDocument["Брокерский договор"],
        "AB"
      );
    },

    isOtherShow(): boolean {
      if (this.files.length) {
        return true;
      }

      return !this.isReadonly;
    },

    isAddtionalUpload(): boolean {
      return this.files.length < FILES_LENGTH && !this.isReadonly;
    },

    isReadonly(): boolean {
      return this.userRepo.isReadonlyKyc;
    },

    isDisabled(): boolean {
      return Object.values(this.documents).some((document) => !document.id);
    },

    isVerificationDisabled(): boolean {
      return this.isLoading || this.userRepo.isReadonlyKyc;
    },
  },
  methods: {
    handleBack(): void {
      this.$emit(
        "update:component",
        VerificationKycComponent.kycPersonalInformation
      );
    },

    async handleUploadFile({
      file,
      document,
    }: {
      file: FileParams;
      document: KycUploadFileResponse;
    }): Promise<void> {
      try {
        this.isLoading = true;
        this.documents[document.name].isUpload = true;

        const uploadFile = await this.kycRepository.uploadFile(
          KycUploadFileRequestFactory({
            file: file.file,
            name: document.name,
            type: file.type,
          })
        );

        this.documents[document.name] =
          KycUploadFileResponseFactory(uploadFile);
      } catch (error) {
        this.documents[document.name] = KycUploadFileResponseFactory({
          name: document.name,
        });
      } finally {
        this.isLoading = false;
        this.documents[document.name].isUpload = false;
      }
    },

    async handleRemoveFile(document: KycUploadFileResponse): Promise<void> {
      try {
        this.isLoading = true;
        this.documents[document.name].isRemove = true;

        await this.kycRepository.deleteFile(document.id!);

        this.documents[document.name] = KycUploadFileResponseFactory({
          name: document.name,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.documents[document.name].isRemove = false;
      }
    },

    async handleUploadAdditionalFile({
      file,
    }: {
      file: FileParams;
    }): Promise<void> {
      try {
        this.isLoading = true;
        this.file.isUpload = true;

        this.files.push(
          await this.userRepository.upload(
            UserFileRequestFactory({
              file: file.file,
              name: file.name,
              fileFor: FileFor.kyc,
              type: file.type as FileType,
            })
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.file.isUpload = false;
      }
    },

    async handleRemoveAdditionalFile(
      document: UserFileResponse
    ): Promise<void> {
      try {
        this.isLoading = true;
        document.isRemove = true;

        await this.userRepository.delete(document.id!);

        this.files.splice(
          this.files.findIndex((file) => file.uuid === document.uuid),
          1
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        document.isRemove = false;
      }
    },

    async handleVerification(): Promise<void> {
      try {
        this.isLoading = true;

        if (this.isDisabled) {
          this.isValidate = true;

          return;
        }

        await this.kycRepository.verification();

        this.userRepo.UPDATE_USER_INFO(await this.userRepository.userInfo());

        this.modalRepo.UPDATE_MODAL(
          ModalFactory({
            title: ModalTitle.application,
            label: ModalLabel.application,
            icon: ModalIcon.application,
            callback: () => {},
          })
        );
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
