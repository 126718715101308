<script lang="ts" src="./component.ts"></script>
<style lang="scss" src="./component.scss"></style>

<template>
  <SharedWindow :class="b()" @close="handleClose">
    <div :class="b('content')">
      <h1 :class="b('title')">Ввод</h1>

      <h4 :class="b('sub-title')">
        Отправьте {{ displayedAmount }}
        {{ displayedDeposit.currencyName.toUpperCase() }} по этим реквизитам:
      </h4>

      <div :class="b('form')">
        <SharedDepositField
          label="Полное фирменное наименование Общества на официальном языке"
          :value="requisites.org_full_name_local"
        />

        <SharedDepositField
          label="Полное фирменное наименование Общества на английском языке"
          :value="requisites.org_full_name_eng"
        />

        <SharedDepositField label="ИНН" :value="requisites.inn" />

        <SharedDepositField
          label="Р/с № (мультивалютный)"
          :value="requisites.current_account"
        />

        <SharedDepositField
          label="Код назначения платежа"
          :value="requisites.payment_code_name"
        />

        <SharedDepositField
          label="Назначение платежа"
          :value="displayedPayment"
        />
      </div>

      <div :class="b('tabs')">
        <button
          :class="b('tab', { active: bank.id === bankId })"
          v-for="bank in requisites.mex_requisite_banks"
          :key="bank.id"
          @click="handleBank(bank.id)"
        >
          {{ handleBankLabel(bank.correspondent_bank) }}
        </button>
      </div>

      <div :class="b('form')">
        <SharedDepositField
          label="БАНК-корреспондент"
          :value="displayedBank.correspondent_bank"
        />

        <SharedDepositField label="БИК" :value="displayedBank.bic" />

        <SharedDepositField label="ИНН" :value="displayedBank.inn" />

        <SharedDepositField
          label="Корр. счет"
          :value="displayedBank.correspondent_account"
        />

        <SharedDepositField
          label="SWIFT"
          :value="displayedBank.correspondent_swift"
        />

        <div></div>

        <SharedDepositField
          label="БАНК получателя"
          :value="displayedBank.recipient_bank"
        />

        <SharedDepositField
          label="Корр. счет"
          :value="displayedBank.recipient_correspondent_account"
        />

        <SharedDepositField
          label="SWIFT"
          :value="displayedBank.recipient_correspondent_swift"
        />
      </div>

      <div :class="b('footer')">
        <p :class="b('footer-label')">
          Нажимая кнопку, вы подтверждаете факт отправки средств на указанный
          адрес. <br :class="b('footer-br')" />
          Ваш баланс обновится после зачисления средств на ваш счет
        </p>

        <UIButton
          :class="b('footer-confirm')"
          view="main"
          label="Подтвердить отправление"
          :disabled="isDisabled"
          @click="handleConfirm"
        />
      </div>
    </div>
  </SharedWindow>
</template>
