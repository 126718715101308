import { defineComponent, PropType } from "vue";

import { COMPONENT_NAME } from "./attributes";

import {
  SharedCard,
  SharedBack,
  SharedUpload,
  SharedDownload,
  SharedValidate,
} from "@/components/shared";
import { UIInput, UIButton } from "@/components/ui";

import { VerificationKybComponent } from "@/views/verification/model";
import {
  KybParticipantResponseFactory,
  KybParticipantUploadFileRequestFactory,
  KybParticipantUploadFileResponseFactory,
} from "@/shared/repository/modules/kyb/factory";
import {
  FileFor,
  FileType,
  KybParticipantDocumentsFileName,
} from "@/shared/constants/enums";
import {
  FileParams,
  KybParticipantDocuments,
} from "@/shared/constants/interfaces";
import { extractFileName } from "@/shared/utils/file-name-helpers";
import { KybParticipantUploadFileResponse } from "@/shared/repository/modules/kyb/repo";
import { ParserParseRequest } from "@/shared/repository/modules/parser/repo";
import { ParserParseRequestFactory } from "@/shared/repository/modules/parser/factory";
import { ParserDocument } from "@/shared/repository/modules/parser/enums";
import {
  UserFileRequestFactory,
  UserFileResponseFactory,
} from "@/shared/repository/modules/user/factory";
import { UserFileResponse } from "@/shared/repository/modules/user/repo";
import { FILES_LENGTH } from "@/shared/repository/modules/user/constants";

export default defineComponent({
  name: COMPONENT_NAME,
  components: {
    SharedCard,
    SharedBack,
    SharedUpload,
    SharedDownload,
    SharedValidate,
    UIInput,
    UIButton,
  },
  props: {
    component: {
      type: <PropType<VerificationKybComponent>>String,
      default: "",
    },
  },
  emits: {
    "update:component": null,
  },
  data() {
    return {
      isValidate: false,
      isLoading: false,
      userRepo: this.$projectServices.userRepo,
      kybRepo: this.$projectServices.kybRepo,
      kybRepository: this.$projectServices.kybRepository,
      userRepository: this.$projectServices.userRepository,
      kybParticipant: KybParticipantResponseFactory(),
      file: UserFileResponseFactory(),
      documents: Object.keys(KybParticipantDocumentsFileName).reduce(
        (documents, fileName) => {
          documents[fileName] = KybParticipantUploadFileResponseFactory({
            name: KybParticipantDocumentsFileName[
              fileName as KybParticipantDocumentsFileName
            ],
            kyb_participant_id: this.$projectServices.kybRepo.participantId,
          });

          return documents;
        },
        {} as KybParticipantDocuments
      ) as KybParticipantDocuments,
      files: <UserFileResponse[]>[],
    };
  },
  async created(): Promise<void> {
    try {
      this.isLoading = true;

      const [kybParticipant, kybParticipantFiles, files] =
        await Promise.allSettled([
          this.kybRepository.participantById(this.kybRepo.participantId!),
          this.kybRepository.participantFiles(
            this.kybRepo.participantId!,
            this.userRepo.userInfo.id
          ),
          this.userRepository.files(
            FileFor.participant,
            this.userRepo.userInfo.id,
            this.kybRepo.participantId!
          ),
        ]);

      if (kybParticipant.status === "fulfilled") {
        this.kybParticipant = kybParticipant.value;
      }

      if (kybParticipantFiles.status === "fulfilled") {
        kybParticipantFiles.value.forEach((kybParticipantFile) => {
          this.documents[extractFileName(kybParticipantFile.name)] =
            KybParticipantUploadFileResponseFactory({
              ...kybParticipantFile,
              kyb_participant_id: this.kybRepo.participantId,
            });
        });
      }

      if (files.status === "fulfilled") {
        this.files = files.value;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    displayedQuestionnaireOfAnIndividual(): ParserParseRequest {
      return ParserParseRequestFactory(
        this.kybParticipant,
        ParserDocument["Участник: aнкета физического лица"]
      );
    },

    displayedConsentToTheProcessingOfPersonalData(): ParserParseRequest {
      return ParserParseRequestFactory(
        this.kybParticipant,
        ParserDocument["Участник: согласие на обработку персональных данных"]
      );
    },

    isOtherShow(): boolean {
      if (this.files.length) {
        return true;
      }

      return !this.isReadonly;
    },

    isAddtionalUpload(): boolean {
      return this.files.length < FILES_LENGTH && !this.isReadonly;
    },

    isReadonly(): boolean {
      return this.userRepo.isReadonlyKyb;
    },

    isDisabled(): boolean {
      return Object.values(this.documents).some((document) => !document.id);
    },

    isContinueDisabled(): boolean {
      return this.isLoading;
    },
  },
  methods: {
    handleBack(): void {
      this.$emit("update:component", VerificationKybComponent.kybParticipant);
    },

    handleContinue(): void {
      if (this.isDisabled) {
        this.isValidate = true;

        return;
      }

      this.kybRepo.UPDATE_PARTICIPANT_ID(null);

      this.$emit("update:component", VerificationKybComponent.kybCompany);
    },

    async handleUploadFile({
      file,
      document,
    }: {
      file: FileParams;
      document: KybParticipantUploadFileResponse;
    }): Promise<void> {
      try {
        this.isLoading = true;
        this.documents[document.name].isUpload = true;

        const uploadFile = await this.kybRepository.participantUploadFile(
          KybParticipantUploadFileRequestFactory({
            file: file.file,
            name: document.name,
            type: file.type,
            kyb_participant_id: this.kybRepo.participantId,
          })
        );

        this.documents[document.name] = KybParticipantUploadFileResponseFactory(
          {
            ...uploadFile,
            kyb_participant_id: this.kybRepo.participantId,
          }
        );
      } catch (error) {
        this.documents[document.name] = KybParticipantUploadFileResponseFactory(
          {
            name: document.name,
            kyb_participant_id: this.kybRepo.participantId,
          }
        );
      } finally {
        this.isLoading = false;
        this.documents[document.name].isUpload = false;
      }
    },

    async handleRemoveFile(
      document: KybParticipantUploadFileResponse
    ): Promise<void> {
      try {
        this.isLoading = true;
        this.documents[document.name].isRemove = true;

        await this.kybRepository.participantDeleteFile(
          document.id!,
          this.kybRepo.participantId!
        );

        this.documents[document.name] = KybParticipantUploadFileResponseFactory(
          {
            name: document.name,
            kyb_participant_id: this.kybRepo.participantId,
          }
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.documents[document.name].isRemove = false;
      }
    },

    async handleUploadAdditionalFile({
      file,
    }: {
      file: FileParams;
    }): Promise<void> {
      try {
        this.isLoading = true;
        this.file.isUpload = true;

        this.files.push(
          await this.userRepository.upload(
            UserFileRequestFactory({
              file: file.file,
              name: file.name,
              fileFor: FileFor.participant,
              type: file.type as FileType,
              kyb_participant_id: this.kybRepo.participantId!,
            })
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.file.isUpload = false;
      }
    },

    async handleRemoveAdditionalFile(
      document: UserFileResponse
    ): Promise<void> {
      try {
        this.isLoading = true;
        document.isRemove = true;

        await this.userRepository.delete(document.id!);

        this.files.splice(
          this.files.findIndex((file) => file.uuid === document.uuid),
          1
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        document.isRemove = false;
      }
    },
  },
});
