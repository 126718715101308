import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SharedButtonIcon = _resolveComponent("SharedButtonIcon")!
  const _component_WalletMainTable = _resolveComponent("WalletMainTable")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('header'))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('tabs'))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedTabs, (tab) => {
          return (_openBlock(), _createElementBlock("button", {
            class: _normalizeClass(_ctx.b('tab', { active: tab.active })),
            key: tab.id,
            onClick: ($event: any) => (_ctx.handleTab(tab.value))
          }, _toDisplayString(_ctx.$t(tab.label)), 11, _hoisted_1))
        }), 128))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('actions'))
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('action-small')),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleWithdrawal && _ctx.handleWithdrawal(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('action-icon', { withdrawal: true })),
            innerHTML: _ctx.displayedIcons.withdrawal
          }, null, 10, _hoisted_2)
        ], 2),
        _createElementVNode("button", {
          class: _normalizeClass(_ctx.b('action-small')),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleDeposit && _ctx.handleDeposit(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('action-icon', { deposit: true })),
            innerHTML: _ctx.displayedIcons.deposit
          }, null, 10, _hoisted_3)
        ], 2),
        _createVNode(_component_SharedButtonIcon, {
          class: _normalizeClass(_ctx.b('action')),
          view: "main-outline",
          onClick: _ctx.handleWithdrawal
        }, {
          "icon-left": _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('action-icon', { withdrawal: true })),
              innerHTML: _ctx.displayedIcons.withdrawal
            }, null, 10, _hoisted_4)
          ]),
          label: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Вывод")), 1)
          ]),
          _: 1
        }, 8, ["class", "onClick"]),
        _createVNode(_component_SharedButtonIcon, {
          class: _normalizeClass(_ctx.b('action')),
          view: "main",
          onClick: _ctx.handleDeposit
        }, {
          "icon-left": _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.b('action-icon', { deposit: true })),
              innerHTML: _ctx.displayedIcons.deposit
            }, null, 10, _hoisted_5)
          ]),
          label: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Ввод")), 1)
          ]),
          _: 1
        }, 8, ["class", "onClick"])
      ], 2)
    ], 2),
    _createVNode(_component_WalletMainTable, { list: _ctx.list }, null, 8, ["list"])
  ], 2))
}