/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import {
  WalletListResponse,
  WalletModal,
  WalletWithAmountResponse,
} from "@/shared/repository/modules/wallet/repo";
import { WalletModalFactory } from "@/shared/repository/modules/wallet/factory";
import { $projectServices } from "@/shared/repository/instances/project-services";
import { WalletFilterType } from "@/shared/repository/modules/wallet/enums";
import { AdminCurrencyType } from "@/shared/repository/modules/admin/currency/enums";

enum WalletMutation {
  UPDATE_MODAL = "UPDATE_MODAL",
  UPDATE_LIST = "UPDATE_LIST",
  UPDATE_WITH_AMOUNT = "UPDATE_WITH_AMOUNT",
}

interface State {
  modal?: WalletModal;
  list?: WalletListResponse[];
  withAmount?: WalletWithAmountResponse[];
}

@Module({
  namespaced: true,
  stateFactory: true,
  name: "wallet",
})
export default class WalletModuleState extends VuexModule<State> {
  modal: WalletModal = WalletModalFactory();
  list: WalletListResponse[] = [];
  withAmount: WalletWithAmountResponse[] = [];

  @Mutation
  [WalletMutation.UPDATE_MODAL](modal: WalletModal): void {
    this.modal = modal;
  }

  @Mutation
  [WalletMutation.UPDATE_LIST](list: WalletListResponse[]): void {
    this.list = list;
  }

  @Mutation
  [WalletMutation.UPDATE_WITH_AMOUNT](
    withAmount: WalletWithAmountResponse[]
  ): void {
    this.withAmount = withAmount;
  }

  @Action
  async wallet(userId: number): Promise<void> {
    try {
      const [list, withAmount, requisites] = await Promise.all([
        $projectServices.walletRepository.list(WalletFilterType.all, userId),
        $projectServices.walletRepository.withAmount(
          WalletFilterType.all,
          userId
        ),
        $projectServices.adminCommissionRepository.requisitesList(),
      ]);

      this.UPDATE_LIST(
        list.filter((item) => {
          if (item.type === AdminCurrencyType.crypto) return item;

          return requisites.find(
            (requisite) => requisite.currency.id === item.currency_id
          );
        })
      );
      this.UPDATE_WITH_AMOUNT(
        withAmount.filter((item) => {
          if (item.currency_type === AdminCurrencyType.crypto) return item;

          return requisites.find(
            (requisite) => requisite.currency.id === item.currency_id
          );
        })
      );
    } catch (error) {
      console.log(error);
    }
  }
}
